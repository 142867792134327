<template>
	<div>
	   <template v-if="screenWidth > 500">
        <section class="banner-wrap">
         <div class="flex">
    	      <div class="banner-left">
              <div class="banner-left-opacity"></div>
            </div>
            <template v-if="loading">
              <PuSkeleton class="banner slick-sliders" style=" height: 650px" />
            </template>
            <template v-else>
      	      <slick ref="slick" :options="slickOptions" class="banner slick-sliders">
                  <div v-for="(banner, bannerIndex) in banners" 
                    :key="bannerIndex"
                    class="banner-sliders item">
                    <a :href="(banner.url != '' ? banner.url : 'javascript:;')">
                      <img :src="banner.desktop_path" class="d-block d-sm-none" alt="Banner">
                      <template v-if="banner.mobile_path">
                        <img :src="banner.mobile_path" class="d-block d-sm-block" alt="Banner">
                      </template>
                      <template>
                        <img :src="banner.desktop_path" class="d-block d-sm-block" alt="Banner">
                      </template>
                    </a>                
                  </div>
      	      </slick>
            </template>
    	   </div>
    	   <div class="container">
            <div class="banner-content">
               <span class="">Sua busca a um clique de distância</span>
               <h1 class="">Encontre o comércio ou serviço perto de você!</h1>
      	       <form-search></form-search>
            </div>
    	   </div>
       </section>
       </template>
     <template v-else>
       <div class="row">
         <div class="col-sm-12 mb-1">
           <template v-if="loading">
              <PuSkeleton class="banner slick-sliders" style=" height: 650px" />
            </template>
            <template v-else>
              <slick ref="slick" :options="slickOptions" class="slick-sliders">
                  <div v-for="(banner, bannerIndex) in banners" 
                    :key="bannerIndex"
                    class=" item">
                    <a :href="(banner.url != '' ? banner.url : 'javascript:;')">
                      <template v-if="banner.mobile_path">
                        <img :src="banner.mobile_path" alt="Banner">
                      </template>
                      <template>
                        <img :src="banner.desktop_path" alt="Banner">
                      </template>                
                    </a>
                  </div>
              </slick>
            </template>
         </div>
         <div class="col-sm-12">
           <div class="container">
              <div class="banner-content">
                 <span class="">Sua busca a um clique de distância</span>
                 <h1 style="font-size: 22px">Encontre o comércio ou serviço perto de você!</h1>
                 <form-search></form-search>
              </div>
           </div>
         </div>
       </div>
     </template>
	</div>
</template>
<script>
  import FormSearch from './FormSearch';	  
  import Slick from 'vue-slick';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'Banners',
    components: { 
      Slick,
      FormSearch,
    },
    computed: {
      ...mapGetters('Banner', ['banners', 'loading']),
      screenWidth(){
        return window.screen.width;
      }
    },
    methods: {
      ...mapActions('Banner', ['getBanners']),
    },
    mounted(){
      this.getBanners();
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 1,
                dots: true,
                autoplay: true,
                autoplaySpeed: 6000,                
            },
        };
    },
  }
</script>
<style scoped>
  .banner-wrap{
    max-height: initial !important;
    max-height: 650px;
  }
  .banner-left-opacity{
    background-color: white; width:100%; opacity: 0.7; filter: alpha(opacity=50); height: 100%; width: 100%
  }
  .banner-left{
    background-image: url('../../../assets/images/bg/bg-diskcerto.png');
    background-size: cover;
    max-height: initial !important;
    max-height: 650px
  }
  .banner.slick-sliders .slick-slide>div { height: initial !important }  
  .banner.slick-sliders img { height: auto !important;}
  .slick-dotted.slick-slider {margin-bottom: 0px !important}  
  .banner.slick-sliders .slick-slide>div { height: 100% !important }
</style>
