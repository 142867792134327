<template>
	<div>
		<form-contact></form-contact>
		<!--  -->
		<partner-info></partner-info>
	</div>
</template>
<script>	
	import PartnerInfo from './PartnerInfo';
	import FormContact from './FormContact';
	import {mapGetters} from 'vuex';

	export default{
		components: {			
			PartnerInfo,
			FormContact,
		}
	}
</script>