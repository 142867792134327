<template>
	<main id="main" class="site-main">
		<div class="site-content owner-content">
			<restrict-menu></restrict-menu>

			<div class="container">
				<div class="member-place-wrap">
					<div class="member-wrap-top">
						<h2>Meus Leads</h2>						
					</div><!-- .member-wrap-top -->
					<div class="member-filter">
						<div class="mf-left">
							<form action="#" method="GET">								
								<div class="field-select">
									<select name="lead_status_id" v-model="params.lead_status_id" @change="loadLeads">
										<option value="">Todos status</option>
										<option value="1">Aguardando atendimento</option>
										<option value="2">Em atendimento</option>
										<option value="3">Concluído</option>
										<option value="4">Descartado</option>
									</select>
									<i class="la la-angle-down"></i>
								</div>
							</form>
						</div><!-- .mf-left -->
						<div class="mf-right">
							<div action="#" class="site__search__form" method="GET">
								<div class="site__search__field">
									<span class="site__search__icon">
										<i class="las la-calendar"></i>
									</span><!-- .site__search__icon -->
									<input class="site__search__input" type="date" name="date_start" v-model="params.date_start_at" @change="loadLeads">
								</div>
							</div><!-- .search__form -->
						</div>
						<div class="mf-right">
							até
						</div>
						<div class="mf-right">
							<div action="#" class="site__search__form" method="GET">
								<div class="site__search__field">
									<span class="site__search__icon">
										<i class="las la-calendar"></i>
									</span><!-- .site__search__icon -->
									<input class="site__search__input" type="date" name="date_end" v-model="params.date_end_at" @change="loadLeads">
								</div>
							</div><!-- .search__form -->
						</div>
					</div><!-- .member-filter -->
					<table v-if="Object.keys(records).length > 0" class="member-place-list owner-booking table-responsive">
						<thead>
							<tr>
								<th>ID</th>
								<th>Cliente</th>
								<th>Email</th>
								<th>Celular</th>
								<th>Produto</th>
								<th>Status</th>
								<th>Data</th>
								<th>Mensagem</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<template v-if="records && records.data.length == 0">
								<tr>
									<td colspan="9">Nenhum registro encontrado</td>
								</tr>
							</template>
							<template v-else>
								<tr v-for="(record, index) in records.data" :key="index">
									<td data-title="ID">{{ record.id }}</td>
									<td data-title="Cliente">{{ record.customer.name }}</td>
									<td data-title="Email">{{ record.customer.email }}</td>
									<td data-title="Celular">{{ record.customer.celular|VMask('(##) #-####-####') }}</td>
									<td data-title="Produto">{{ record.product }}</td>
									<td data-title="Status" :class="record.lead_status.html_class">{{ record.lead_status.name }}</td>
									<td data-title="Data">{{ record.created_at|moment('DD/MM/YYYY') }}</td>
									<td data-title="Mensagem">{{ record.message }}</td>
									<td data-title="" class="place-action">
										<template v-if="record.lead_status.slug == 'waiting'">
											<a @click="changeStatus(record.id, 2)" href="javascript:;" class="detail" style="line-height: 15px">Em atendimento</a>
										</template>
										<template v-else-if="record.lead_status.slug == 'processing'">
											<a @click="changeStatus(record.id, 4)" href="javascript:;" class="cancel" title="Cancel">Descartar</a>
											<a @click="changeStatus(record.id, 3)" href="javascript:;" class="approve" title="Detail">Concluído</a>
										</template>
									</td>
								</tr>
							</template>							
						</tbody>
					</table>
					<!--  -->
			        <div v-if="records.last_page > 1" class="pagination">
			        	<div class="pagination__numbers">
			              <a v-if="records.current_page > 1" title="Next" :href="url + records.prev_page_url">
			              <i class="la la-angle-left"></i>
			              </a>
			              <template v-for="n in records.last_page">
			                 <template v-if="records.current_page == n">
			                    <span :key="n">{{n}}</span>
			                 </template>
			                 <template v-else>
			                    <a :key="n" :title="n" :href="url+'?page='+n">{{n}}</a>
			                 </template>
			              </template>
			              <a v-if="records.current_page < records.last_page" 
			                 title="Next" :href="url+records.next_page_url">
			              <i class="la la-angle-right"></i>
			              </a>
			           </div>
			        </div>
			        <!--  -->
				</div><!-- .member-place-wrap -->
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import RestrictMenu from '../Menu';
	import Partner from '@/api/Partner';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components:{
			RestrictMenu
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		methods: {
			...mapActions('Site', ['loading']),
			loadLeads(){
				const self = this;				

				this.loading(true)
				Partner.myLeads(this.params).then(response => {
					let data = response.data.data;
					self.records = data;
					this.loading(false)
				});
			},
			changeStatus(lead, status){
				let params = {};
				params.lead_status_id = status;
				params.id = lead;

				this.loading(true)
				Partner.leadStatus(params).then(response => {					
					this.loading(false);
					this.loadLeads();
				});
			}
		},
		mounted(){
			this.loadLeads();
			this.url = window.location.origin +''+ window.location.pathname;
		},
		data(){
			return {				
				records: {},
				url: '',
				params: {
					lead_status_id: '',
					date_start_at: '',
					date_end_at: '',
				}
			}
		}
	}
</script>
<style scoped>
	.member-place-list td.waiting{
	    color: #ffcc00 !important;
	}

	.member-place-list td.processing {
	    color: orange !important;
	}

	.member-place-list td.success {
	    color: #2dcb48 !important;
	}

	.member-place-list td.trash {
	    color: #e74807 !important;
	}
</style>