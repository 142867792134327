<template>
	<div>
		<template v-if="loading">
			<PuSkeleton style="height: 20px" :count="8" />
		</template>
		<template v-else-if="Object.keys(partner).length > 0 
		&& partner.plan.permissions.indexOf('ads-hotsite') != -1
		&& partner.products.length > 0">
			<div class="place__box place__box-map">
			   <h3 class="place__title--additional">
			      Produtos destaques
			   </h3>
			   <div class="menu-tab">
			      <ul>
			      	<template v-for="(category, categoryName, categoryIndex) in partner.product_categories">
			      		<li :class="{active: (categoryIndex == 0)}" :key="categoryName">
			      			<a :href="'#category-'+categoryIndex" :title="categoryName">
			      				{{categoryName}}
			      			</a>
			      		</li>
			      	</template>			         
			      </ul>
			      <template v-for="(products, categoryName, categoryIndex) in partner.product_categories">
				      <div :key="categoryIndex"
				      		:class="{'menu-wrap': true, active: (categoryIndex == 0)}"
				      		:id="'category-'+categoryIndex">
				         <div class="flex">
				            <div v-for="product in products" :key="product.name" class="menu-item">
				               <img v-if="product.images_path.length > 0"
				               	:src="product.images_path[0]" :alt="product.name">
				               <img v-else src="@/assets/images/assets/produto-semimagem.jpg" :alt="product.name">
				               <div class="menu-info">
				                  <h4>{{product.name}}</h4>
				                  <p>{{product.description}}</p>
				                  <span v-if="product.price > 0" class="price">{{product.price|currency}}</span>
				               </div>
				            </div>			           
				         </div>
				         <a href="#" class="menu-more">Show more</a>
				      </div>
			      </template>
			   </div>
			   <div :style="loadEvents()"></div>
			</div>
		</template>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex';
	import $ from 'jquery';

	export default{
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		},
		methods: {
			loadEvents(){
				setTimeout(() => {					
					$( '.menu-tab li a' ).on( 'click', function(e) {
			            e.preventDefault();
			            var tab = $( this ).attr( 'href' );
			            $( '.menu-tab li' ).removeClass( 'active' );
			            $( this ).parents( 'li' ).addClass( 'active' );
			            $( '.menu-wrap' ).removeClass( 'active' );
			            $( tab ).addClass( 'active' );
			        });
			        
					$( '.menu-more' ).on( 'click', function(e) {
			            e.preventDefault();
			            $( this ).parents( '.menu-wrap' ).find( '.flex' ).addClass('open');
			            $(this).fadeOut(0);
			        });
				},1000);
			}
		},
		mounted(){			
		}
	}
</script>