<template>
	<main id="main" class="site-main contact-main">
		<div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-about.png);">
			<div class="container">
				<div class="page-title__content">
					<h1 class="page-title__name">Recuperar senha</h1>
					<p class="page-title__slogan">Não consegue conectar? Não se preocupe. Nós ajudaremos.</p>
				</div>
			</div>	
		</div><!-- .page-title -->
		<div class="site-content">
			<div class="container">
				<div class="row">					
					<div class="col-md-12" align="center">
						<div v-if="success" class="alert alert-success mt-5">
							<p>
							Enviamos para o seu e-mail o procedimento de recuperação da sua senha.
							Verifique o SPAM caso não encontre na página principal.						
							</p>

							<a :href="$root.getUrlPath('partner-login')" title="Get Direction"> voltar para o login</a>
						</div>
						<div v-else class="contact-form">
							<h2>Recuperar minha senha</h2>
							<form ref="formRecover" @submit.prevent="handleSubmit" class="form-underline">
								<div class="field-input">
									<input type="email" name="email" value="" placeholder="Email da sua conta" required>
								</div>								
								<div class="field-submit mb-4">
									<input type="submit" value="Recuperar senha" class="btn">
								</div>
							</form>
							<a :href="$root.getUrlPath('partner-login')" title="Get Direction"> voltar para o login</a>
						</div>						
					</div>
				</div>
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>
	import Partner from '@/api/Partner';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		methods:{
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),	
			async handleSubmit(){
		        const self = this;

				if (this.$refs.formRecover.checkValidity()) {
				  let params = await this.$root.getAllData(this.$refs.formRecover);				  

				  this.setLoading(true);
				  Partner.recoverPass(params).then(response => {
				  	this.success = true;
				  	this.setLoading(false);
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formRecover.reportValidity();
				}
		    },
		},
		data(){
			return{
				success: false,
			}
		}
	}
</script>