<template>
	<main id="main" class="site-main">
		<div class="site-content owner-content">
			<!--  -->
			<restrict-menu></restrict-menu>
			<!--  -->
			<restrict-dashboard></restrict-dashboard>
		</div><!-- .site-content -->
	</main>
</template>
<script>
	import RestrictMenu from './Menu';
	import RestrictDashboard from './Dashboard';

	export default{
		components:{
			RestrictMenu,
			RestrictDashboard,
		}
	}
</script>