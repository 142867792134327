<template>
	<ul class="place__comments">
		<template v-if="ratings && ratings.length > 0">
			<li v-for="(rating, index) in ratings" :key="index">
				<div class="place__author">
					<div class="place__author__avatar">
						<a title="Author" href="#"><img src="@/assets/images/assets/user-default.png" alt=""></a>
					</div>
					<div class="place__author__info">
							<a title="Author" href="#">{{ rating.customer.name }}</a>
							<div class="place__author__star">
								<i v-for="star in 5" 
		               			:key="star" 
		               			:class="{
			            			'las la-star': true, 
			            			'color-orange': (rating.rating >= star)
			            		}"></i>	
							</div>
						<span class="time">{{rating.created_at|moment('DD/MM/YYYY')}}</span>
					</div>
				</div>
				<div class="place__comments__content" v-html="rating.comment">				
				</div>			
			</li>	
		</template>
		<template v-else>
			<li>
				Nenhuma avaliação feita até o momento.
			</li>
		</template>	
	</ul>
</template>
<script>
	export default{
		props:{
			ratings: [Array, Object],
		}
	}
</script>
<style scoped>
	.color-orange{
	    color: orange !important;
	}	
</style>