<template>
	<section class="blogs-wrap section-bg">
      <div class="container">
        <div class="title_home ">
          <h2>Fique por dentro das novidades</h2>
        </div>
        <div class="blog-wrap ">
          <div class="row">
          	<template v-if="loading">
          		<PuSkeleton v-for="n in 3" :key="n" style="height: 270px" />
          	</template>
          	<template v-else>
          		<template v-for="(post, postIndex) in posts.data">
		            <div :key="postIndex" class="col-md-4">
		              <article class="post hover__box">
		                <div class="post__thumb hover-img">
		                  <a :title="post.name" :href="$root.getUrlPath('blog-details', {slug: post.slug})">
                        <img v-lazy="post.image_path" :alt="post.name">
                      </a>
		                </div>
		                <div class="post__info">
		                  <ul v-if="post.tags && post.tags.length > 0" class="post__category">
		                    <li v-for="tag in post.tags" :key="tag">
                          <a href="javascript:;">{{ tag }}</a>
                        </li>                          
		                  </ul>
		                  <h3 class="post__title">
                        <a :title="post.name" :href="$root.getUrlPath('blog-details', {slug: post.slug})">
                          {{post.name}}
                        </a>
                      </h3>
		                </div>
		              </article>
		            </div>		            
	        	</template>
          	</template>
          </div>
          <div class="button-wrap">
            <a :href="$root.getUrlPath('blog')" class="btn" title="View more">Ver mais conteúdo</a>
          </div>
        </div>
      </div>
    </section>
</template>
<script>      
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'BlogSection',
    components: {       
    },
    computed: {
      ...mapGetters('Blog', ['posts', 'loading']),
    },
    methods: {
      ...mapActions('Blog', ['getPostsBlogs']),
    },
    mounted(){
      this.getPostsBlogs({limit: 3});      
    },
    data() {
        return {          
        };
    },
  }
</script>