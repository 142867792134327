<template>
	<div class="place__box">
		<template v-if="loading">
			<PuSkeleton style="height: 160px;" />
		</template>
		<template v-else-if="Object.keys(partner).length > 0">
			<div class="place__desc">
				<div v-for="(contact, contactIndex) in partner.contacts" :key="contactIndex * 100">
	               <a v-if="contact.type == 'phone'"	                  
	                  :href="'tel:'+(contact.value.length == 10 || contact.value.length == 11 ? `0${contact.value}` : contact.value)">
	               		<i class="las la-phone"></i> {{ contact.value|VMask('(##) ####-####')  }}
	               </a>
	               <a v-if="contact.type == 'mobile'"
	                  :href="'tel:'+(contact.value.length == 10 || contact.value.length == 11 ? `0${contact.value}` : contact.value)">
	               		<i class="las la-mobile"></i> {{ contact.value|VMask('(##) #-####-####')  }}
	               </a>
	               <a v-if="contact.type == 'whatsapp'"
	                  :href="'tel:'+(contact.value.length == 10 || contact.value.length == 11 ? `0${contact.value}` : contact.value)">
	               		<i class="lab la-whatsapp"></i> {{ contact.value|VMask('(##) #-####-####')  }}
	               </a>	              
	            </div>
			</div>
			<template v-if="partner.plan.permissions.indexOf('ads-hotsite') != -1">
				<h3>Descrição da empresa</h3>	
				<div class="place__desc" v-html="partner.description">
				</div>
				<div v-if="partner.image_description_path" align="center">
					<img :src="partner.image_description_path" alt="Image description" class="img-fluid">
				</div>			
			</template>
		</template>	     	    
    </div>
</template>
<script>
	import {mapGetters} from 'vuex';

	export default{		
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		}
	}
</script>