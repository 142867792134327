<template>
	<main id="main" class="site-main">
		<div class="site-content owner-content">
			<restrict-menu></restrict-menu>

			<div class="container">
				<div class="member-place-wrap">
					<div class="member-wrap-top">
						<h2>Meus Produtos</h2>						
					</div><!-- .member-wrap-top -->
					<div class="member-filter">
						<div class="mf-left">
							<form action="#" method="GET">								
								<div class="field-select">
									<select name="is_active" v-model="params.is_active" @change="loadLeads">
										<option value="">Todos</option>
										<option value="1">Ativos</option>
										<option value="0">Desativados</option>
									</select>
									<i class="la la-angle-down"></i>
								</div>
							</form>
						</div>				
						<div class="mf-right">
							<router-link :to="{name: 'partner-product-create'}" class="btn btn-blue">Novo registro</router-link>
						</div>
					</div><!-- .member-filter -->
					<table v-if="Object.keys(records).length > 0" class="member-place-list owner-booking table-responsive">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nome</th>
								<th>Categoria</th>
								<th>Preço R$</th>
								<th>Ativo?</th>								
								<th>Data</th>								
								<th></th>
							</tr>
						</thead>
						<tbody>
							<template v-if="records && records.data.length == 0">
								<tr>
									<td colspan="7">Nenhum registro encontrado</td>
								</tr>
							</template>
							<template v-else>
								<tr v-for="(record, index) in records.data" :key="index">
									<td data-title="ID">{{ record.id }}</td>
									<td data-title="Nome">{{ record.name }}</td>
									<td data-title="Categoria">{{ record.category }}</td>
									<td data-title="Preço">{{ record.price|currency }}</td>
									<td data-title="Ativo">{{ record.is_active ? 'Sim' : 'Não' }}</td>
									<td data-title="Data">{{ record.created_at|moment('DD/MM/YYYY') }}</td>
									<td class="place-action">
										<router-link :to="{name: 'partner-product-update', params:{id: record.id}}" class="approve" title="Detail">Editar</router-link>
									</td>
								</tr>
							</template>							
						</tbody>
					</table>
					<!--  -->
			        <div v-if="Object.keys(records).length > 0 && records.last_page > 1" class="pagination">
			        	<div class="pagination__numbers">
			              <a v-if="records.current_page > 1" title="Next" :href="url + records.prev_page_url">
			              <i class="la la-angle-left"></i>
			              </a>
			              <template v-for="n in records.last_page">
			                 <template v-if="records.current_page == n">
			                    <span :key="n">{{n}}</span>
			                 </template>
			                 <template v-else>
			                    <a :key="n" :title="n" :href="url+'?page='+n">{{n}}</a>
			                 </template>
			              </template>
			              <a v-if="records.current_page < records.last_page" 
			                 title="Next" :href="url+records.next_page_url">
			              <i class="la la-angle-right"></i>
			              </a>
			           </div>
			        </div>
			        <!--  -->
				</div><!-- .member-place-wrap -->
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import RestrictMenu from '../Menu';
	import Partner from '@/api/Partner';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components:{
			RestrictMenu
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		methods: {
			...mapActions('Site', ['loading']),
			loadLeads(){
				const self = this;				

				this.loading(true)
				Partner.myProducts(this.params).then(response => {
					let data = response.data.data;
					self.records = data;
					this.loading(false)
				});
			},
		},
		mounted(){
			this.loadLeads();
			this.url = window.location.origin +''+ window.location.pathname;
		},
		data(){
			return {				
				records: {},
				url: '',
				params: {
					is_active: '',
				}
			}
		}
	}
</script>
<style scoped>
	.member-place-list td.waiting{
	    color: #ffcc00 !important;
	}

	.member-place-list td.processing {
	    color: orange !important;
	}

	.member-place-list td.success {
	    color: #2dcb48 !important;
	}

	.member-place-list td.trash {
	    color: #e74807 !important;
	}
</style>