<template>
   <section v-if="Object.keys(partner).length > 0 && partner.plan_id == 4" class="restaurant-wrap section-bg">
      <div class="container">
         <div class="title_home ">
            <h2>Empresas semelhantes</h2>
         </div>
         <template v-if="loading">
            <PuSkeleton style="height: 80px" />
         </template>
         <template v-else>
           <slick ref="slick" :options="slickOptions" class="restaurant-sliders slick-sliders">
              <!--  -->
              <template v-for="(partnerRelated, index) in related">
                <div :key="index" style="padding: 8px;">
                <card-partner :partner="partnerRelated" :options="{showContact: false, fontHome: true}"></card-partner>
                </div>
              </template>
              <!--  -->
           </slick>
         </template>
      </div>
   </section>
</template>
<script>
	import CardPartner from '@/components/CardPartner';
	import Slick from 'vue-slick';
	import {mapGetters} from 'vuex';

	export default{		
		components:{
			Slick,
			CardPartner,
		},
		computed: {
			...mapGetters('Partner', ['loading', 'partner', 'related']),
		},
		data() {
        	return {
	            slickOptions: {
	                slidesToShow: 4,
	                slidesToScroll: 4,
	                dots: true,
	                autoplay: true,
	                autoplaySpeed: 4000,
	                arrows: true,
	                centerPadding: '20px',
	                responsive: [
	                   	{
	                        breakpoint: 992,
	                        settings: {
	                            slidesToShow: 2,
	                            slidesToScroll: 2,                            
	                        }
	                    },
	                    {
	                        breakpoint: 575,
	                        settings: {
	                            slidesToShow: 1,
	                            slidesToScroll: 1,
	                            arrows: true,                            
	                            prevArrow: '',
	                            nextArrow: '',
	                        }
	                    },
	                ]            
	            },
        	}
        }  
	}
</script>