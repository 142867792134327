<template>
	<div>
		<h3>Informações de contato</h3>
		<div class="row mb-3">
			<div class="col-sm-6">
				<label for="place_email">Email</label>
				<input type="email" placeholder="Endereço de email" name="email" v-model="form.email" required>
			</div>
			<div class="col-sm-6">
				<label for="place_email">Senha de acesso</label>
				<input type="password" placeholder="Senha para area restrita" name="password" :required="Object.keys(this.user).length == 0">
			</div>
		</div>
		<div class="row">
			<div class="col-sm-6 mb-3">
				<label for="place_number">Telefone Fixo (optional)</label>
				<input type="hidden" name="contacts[0][type]" value="phone">
				<the-mask name="contacts[0][value]" :value="contacts('phone')" :mask="['(##) ####-####']" placeholder="Telefone*" />
			</div>
			<div class="col-sm-6 mb-3">
				<label for="place_number">Celular (optional)</label>
				<input type="hidden" name="contacts[1][type]" value="mobile">
				<the-mask name="contacts[1][value]" :value="contacts('mobile')" :mask="['(##) #-####-####']" placeholder="Celular*" />
			</div>
			<div class="col-sm-6 mb-3">
				<label for="place_number">WhatsApp (optional)</label>
				<input type="hidden" name="contacts[2][type]" value="whatsapp">
				<the-mask name="contacts[2][value]" :value="contacts('whatsapp')" :mask="['(##) #-####-####']" placeholder="WhatsApp*" />
			</div>		
			<div class="col-sm-6 mb-3">
				<label for="place_website">Site (optional)</label>
				<input type="url" placeholder="Link do site" v-model="form.site" name="site">
			</div>
			<div class="col-sm-6 mb-3">
				<label for="place_website">Facebook (optional)</label>
				<input type="url" placeholder="Link do facebook" v-model="form.facebook" name="facebook">
			</div>
			<div class="col-sm-6 mb-3">
				<label for="place_website">LinkedIn (optional)</label>
				<input type="url" placeholder="Link do linkedin"  v-model="form.linkedin" name="linkedin">
			</div>
			<div class="col-sm-6 mb-3">
				<label for="place_website">Youtube (optional)</label>
				<input type="url" placeholder="Link do youtube"  v-model="form.youtube" name="youtube">
			</div>
		</div>
	</div>
</template>
<script>
	import {TheMask} from 'vue-the-mask';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components: {
			TheMask,
		},
		computed:{
			...mapGetters('Partner', ['user']),			
		},
		methods:{
			contacts(type){
				if(Object.keys(this.user).length > 0){
					let param = this.user.contacts.find((value, index) => {
						return value.type == type;
					})

					return param.value;
				}

				return '';
			}
		},
		mounted(){
			if(Object.keys(this.user).length > 0){
				this.form = this.user;
			}
		},
		data(){
			return{				
		        form: {
		        	contacts:[]
		        },		       
			}
		}
	}
</script>