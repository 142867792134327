<template>
	<main id="main" class="site-main single single-02">
	   <div class="place">
	   		<partner-header></partner-header>
	      <!-- .place-slider -->
	      <div class="container">
	         <div class="row">
	            <div class="col-lg-8">
	               <div class="place__left">
	                  <!--  -->
	                  <partner-title></partner-title>
	                  <!-- .place__box -->
	                  <partner-description></partner-description>
	                  <!--  -->
	                  <partner-products></partner-products>
	                  <!-- .place__box -->
	                  <partner-reviews></partner-reviews>
	                  <!-- .place__box -->
	               </div>
	               <!-- .place__left -->
	            </div>
	            <div class="col-lg-4">
	               <partner-side-right></partner-side-right>
	               <!-- .sidebar -->
	            </div>
	         </div>
	      </div>
	   </div>
	   <!-- .place -->
	   <partner-related></partner-related>
	   <!-- .similar-places -->
	</main>
</template>
<script>
	import PartnerHeader from './PartnerHeader';
	import PartnerTitle from './PartnerTitle';
	import PartnerDescription from './PartnerDescription';
	import PartnerProducts from './PartnerProducts';
	import PartnerRelated from './PartnerRelated';
	import PartnerReviews from './PartnerReviews';
	import PartnerSideRight from '../PartnerSideRight';
	import {mapGetters, mapActions} from 'vuex';
	
	export default{
		components: {
			PartnerHeader,
			PartnerTitle,
			PartnerDescription,
			PartnerProducts,
			PartnerRelated,
			PartnerReviews,
			PartnerSideRight,
		},
		computed:{
			...mapGetters('Partner', ['partner']),
		},
		methods: {
			...mapActions('Partner', ['partnerDetails', 'setClickStats']),
		},
		watch:{
			partner(promise){
				let payload = {
					type: ('view-page'),				
					partner_id: promise.id,
				}

				this.setClickStats(payload);
			}
		},
		mounted(){
			let params = {}
			params.slug = this.$route.params.slug;
			this.partnerDetails(params);
		}
	}
</script>