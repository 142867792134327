<template>
   <section class="restaurant-wrap section-bg">
      <div class="container">
         <div class="title_home ">
            <h2>Onder ir</h2>
         </div>
         <template v-if="loading">
            <PuSkeleton style="height: 80px" />
         </template>
         <template v-else>
           <slick ref="slick" :options="slickOptions" class="restaurant-sliders slick-sliders">
              <!--  -->
              <template v-for="(partner, index) in lastPartners">
                <div :key="index" style="padding: 9px;">
                <card-partner :partner="partner.partner" :options="{showContact: false, fontHome: true}"></card-partner>
                </div>
              </template>
              <!--  -->
           </slick>
         </template>
      </div>
   </section>
</template>
<script>    
  import CardPartner from '@/components/CardPartner';
  import Slick from 'vue-slick';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'Banners',
    components: { 
      Slick,
      CardPartner,
    },
    computed: {
      ...mapGetters('Partner', ['lastPartners', 'loading'])
    },
    methods: {
      ...mapActions('Partner', ['lastPartnerView'])
    },
    mounted(){
      this.lastPartnerView();
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: true,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: true,
                centerPadding: '20px',
                responsive: [
                    // {
                    //     breakpoint: 1200,
                    //     settings: {
                    //         slidesToShow: smallpcItem,
                    //         slidesToScroll: smallpcScroll,
                    //         centerPadding: smallpcPadding,
                    //     }
                    // },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,                            
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,                            
                            prevArrow: '',
                            nextArrow: '',
                        }
                    },
                ]            
            },
        };
    },
  }
</script>