<template>
  <main id="main" class="site-main overflow">
    <!--  -->
    <banner></banner>
    <!-- .banner-wrap -->
    <top-partners></top-partners>
    <!-- .top-wrap -->
    <featured-section></featured-section>
    <!--  -->
    <blog-section></blog-section>
    <!-- .blogs-wrap -->
    <depoiments></depoiments>
  </main><!-- .site-main -->
</template>

<script>
  import Banner from './Banners';
  import Depoiments from '@/components/Depoiments';
  import TopPartners from './TopPartners';
  import FeaturedSection from './FeaturedSection';
  import BlogSection from './BlogSection';

  export default {
    components: {
      Banner,
      TopPartners,
      FeaturedSection,
      BlogSection,
      Depoiments,
    }
  }
</script>
