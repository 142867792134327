<template>
	<section class="home-testimonials testimonials">
		<div class="container">
			<div class="title_home">
				<h2>O que nossos parceiros estão falando sobre nós</h2>
			</div>
			<div v-if="depoiments.length > 0" class="testimonial-sliders slick-sliders">
				<slick :options="slickOptions" class="testimonial-slider slick-slider" data-item="2" data-itemScroll="2" data-arrows="true" data-dots="true" data-tabletItem="1" data-tabletScroll="1" data-mobileItem="1" data-mobileScroll="1">
					<div v-for="(dep , index) in depoiments" :key="index" class="item">
						<div class="testimonial-item flex">
							<div class="testimonial-thumb">
								<img v-if="dep.image_path" :src="dep.image_path" alt="Avatar" class="avatar">
                                <img v-else src="@/assets/images/member-avatar.png" alt="Avatar" class="avatar">
								<img src="@/assets/images/assets/quote-active.png" alt="quote" class="quote">
							</div>
							<div class="testimonial-info">
								<p v-html="dep.message"></p>
								<div class="cite">
									<h4>{{dep.name}}</h4>
									<span>{{dep.partner}}</span>
								</div>
							</div>
						</div>
					</div>                    		
				</slick><!-- .testimonial-slider -->				
			</div><!-- .testimonial-sliders -->
		</div>
	</section>
</template>
<script>      
    import Site from '@/api/Site'
  import Slick from 'vue-slick';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'Depoiments',
    components: { 
      Slick,      
    },
    computed: {      
    },
    methods: {      
    },
    mounted(){  
        Site.getDepoiments().then(response => {
            this.depoiments = response.data.data;
        })    
    },
    data() {
        return {
            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: true,
                // centerPadding: '20px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,                            
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,                            
                            prevArrow: '',
                            nextArrow: '',
                        }
                    },
                ]            
            },
            depoiments: [],
        };
    },
  }
</script>