<template>
	<main id="main" class="site-main">
	   <div class="page-title page-title--small page-title--blog align-left" 
	   style="background-color: rgb(0, 152, 218);">
	      <div class="container">
	         <div class="page-title__content">
	            <h1 class="page-title__name">Blog</h1>
	            <p class="page-title__slogan">Fique por dentro das novidades que estão acontecendo próximo a você!</p>
	         </div>
	      </div>
	   </div>
	   <!-- .page-title -->
	   <div class="page-content isotope">
	      <div class="container">
	         <!-- <div class="isotope__nav">
	            <ul>
	               <li><a title="All" href="#" class="active" data-filter="*">All (59)</a></li>
	            </ul>
	         </div> -->
	         <!-- .isotope__nav -->
	         <div class="post-grid columns-3">
	         	<template v-if="loading">
	         		<article v-for="n in 3" :key="n" class="hover__box isotope__grid__item post beaches">
	         			<PuSkeleton style="height: 300px" />
	         		</article>
	         	</template>
	         	<template v-else>
	         		<article v-for="post in posts.data" :key="post.id"
	         			class="hover__box isotope__grid__item post beaches">
		               <div v-if="post.image_path" class="post__thumb hover__box__thumb">
		                  <a :title="post.name"
		                  	:href="$root.getUrlPath('blog-details', {slug: post.slug})">
		                  		<img v-lazy="post.image_path" :alt="post.name">
		                  	</a>
		               </div>
		               <div class="post__info">
		                  <ul v-if="post.tags && post.tags.length > 0" class="post__category">
		                    <li v-for="tag in post.tags" :key="tag">
	                          <a href="javascript:;">{{ tag }}</a>
	                        </li>                          
		                  </ul>
		                  <h3 class="post__title">
		                  	<a :title="post.name"
		                  	:href="$root.getUrlPath('blog-details', {slug: post.slug})">
		                  		{{post.name}}
		                  	</a>
		                  </h3>
		               </div>
		            </article>
	         	</template>
	         </div>
	         <!-- .isotope__grid -->
	         <div v-if="Object.keys(posts).length > 0 && posts.last_page > 1" class="pagination">
	            <div class="pagination__numbers">
	               <a v-if="posts.current_page > 1" title="Next" :href="posts.prev_page_url">
	               	<i class="la la-angle-left"></i>
	               </a>
	               <template v-for="n in posts.last_page">	               	
	               		<template v-if="posts.current_page == n">
	               			<span :key="n">{{n}}</span>
	               		</template>
	               		<template v-else>
	               			<a :key="n" :title="n" :href="'/blog?page='+n">{{n}}</a>
	               		</template>	               
	               </template>	               
	               <a v-if="posts.current_page < posts.last_page" 
	               title="Next" :href="posts.next_page_url">
	               	<i class="la la-angle-right"></i>
	               </a>
	            </div>
	         </div>
	         <!-- .pagination -->
	      </div>
	   </div>
	</main>
	<!-- .site-main -->
</template>
<script>      
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'BlogPage',
    components: {       
    },
    computed: {
      ...mapGetters('Blog', ['posts', 'loading']),
    },
    methods: {
      ...mapActions('Blog', ['getPostsBlogs']),
    },
    mounted(){
    	let params = {};
    	if(this.$route.query.page){
    		params.page = this.$route.query.page;
    	}
    	this.getPostsBlogs(params);
    },
    data() {
        return {          
        };
    },
  }
</script>