<template>
	<div>
		<template v-if="loading">
			<PuSkeleton style="height: 90px;" />
		</template>
		<template v-else-if="Object.keys(partner).length > 0">
			<div
				v-if="partner.plan.permissions.indexOf('ads-hotsite') != -1 && partner.image_cover_path" 
				class="single-slider slick-sliders">
			   <div class="slick-slider">
			      <div class="place-slider__item bd">
			      	<img :src="partner.image_cover_path" :alt="partner.name">
			      </div>
			   </div>
			   <!-- .page-title -->
			   <partner-share></partner-share>
			   <!--  -->
			</div>
			<div
				v-else			
				class="page-title page-title--small page-title--blog align-left d-none d-sm-block" 
		   		style="background-color: rgb(0, 152, 218);">
			   <div class="container">
			      <div class="page-title__content">
			         <h1 class="page-title__name">{{ partner.name }}</h1>
			         <p class="page-title__slogan">{{ partner.description_short }}</p>
			      </div>
			   </div>
			   <!-- .page-title -->
			   <partner-share></partner-share>
			   <!--  -->
			</div>
		</template>
	</div>
</template>
<script>
	import PartnerShare from './PartnerShare';
	import {mapGetters} from 'vuex';

	export default{
		components:{
			PartnerShare,
		},
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		}
	}
</script>