<template>
	<div class="area-places">
    	<template v-if="loading">
    		<PuSkeleton v-for="n in 5" :key="n" style="width: 100%; height: 80px; margin-top: 20px" />
    	</template>
    	<template v-else>
        <template v-for="(partner, index) in partners.data" >
    		  <card-partner :partner="partner" :key="index" :options="{showContact: true}"></card-partner>
        </template>
        <!--  -->
        <div v-if="partners.last_page > 1" class="pagination">
        	<div class="pagination__numbers">
              <a v-if="partners.current_page > 1" title="Next" :href="url + partners.prev_page_url">
              <i class="la la-angle-left"></i>
              </a>
              <template v-for="n in partners.last_page">
                 <template v-if="partners.current_page == n">
                    <span :key="n">{{n}}</span>
                 </template>
                 <template v-else>
                    <a :key="n" :title="n" :href="url+'?page='+n">{{n}}</a>
                 </template>
              </template>
              <a v-if="partners.current_page < partners.last_page" 
                 title="Next" :href="url+partners.next_page_url">
              <i class="la la-angle-right"></i>
              </a>
           </div>
        </div>
        <!--  -->
    	</template>
    </div>
</template>
<script>
	import CardPartner from '@/components/CardPartner';
	import {mapGetters, mapActions} from 'vuex';	

	export default{
		components:{
			CardPartner,			
		},
		computed:{
			...mapGetters('Partner', ['loading', 'partners']),
		},
		methods: {
			...mapActions('Partner', ['searchList', 'setClickStats']),
			seeContact($event, contact, partner){
				let $target = $event.target;
				$target.setAttribute('href', 'tel:'+contact.value);
				$target.classList.remove('phone-blocked');

				let params = {
					type: ('view-'+contact.type),
					value: (contact.value),
					partner_id: partner.id,
				}

				this.setClickStats(params);
			}
		},
		mounted(){
			let params = {};
			params.cityCode = this.$route.params.cityCode;
			params.citySlug = this.$route.params.citySlug;
			params.categorySlug = this.$route.params.categorySlug;
			params.term = this.$route.params.term;
			params.page = this.$route.query.page ? this.$route.query.page : 1;

			this.searchList(params);
			this.url = window.location.origin +''+ window.location.pathname;
		},
		data(){
			return{
				url: '',
			}
		}
	}
</script>
