<template>
   <section class="featured-home featured-wrap">
      <div class="container">
         <div class="title_home ">
            <h2>Descubra novos lugares</h2>
            <p>Tudo o que você procura, você encontra aqui.</p>
         </div>
         <div class="featured-inner ">
            <template v-if="loadingSkeleton || Object.keys(sections).length == 0">
               <div class="row">
                  <PuSkeleton v-for="n in 4" :key="n" style="height: 250px" class="col-sm-3" />
               </div>
            </template>
            <template v-else>
               <slick ref="slick" :options="slickOptions">
                  <div v-for="(sectionRow, rowIndex) in sections.rows" :key="rowIndex" class="item">
                     <div class="flex">
                        <div v-for="(group, groupIndex) in sectionRow" 
                           :key="(groupIndex + 100)" class="flex-col">
                           <div v-for="(section) in group" :key="(section.id * 1001)" class="cities">
                              <div class="cities-inner">
                                 <a :href="(section.url ? section.url : 'javascript:;')" class="hover-img">
                                    <span class="entry-thumb">
                                       <img :src="section.image_path" 
                                        :alt="'Imagem destaques '+groupIndex"
                                        :style="(groupIndex == 1 ? 'max-height: 570px' : 'max-height: 270px')">
                                    </span>
                                    <span class="entry-details">
                                       <h3>{{ section.description }}</h3>
                                       <!-- <span>80 places</span> -->
                                    </span>
                                 </a>
                              </div>
                           </div>
                        </div>                       
                     </div>
                  </div>
               </slick>
            </template>
         </div>
         <!-- .featured-inner -->
      </div>
      <!-- .container -->
   </section>
   <!-- .featured-wrap -->
</template>
<script>    
  import Slick from 'vue-slick';
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'FeatiredSection',
    components: { 
      Slick,      
    },
    computed: {
      ...mapGetters('Site', ['sections', 'loadingSkeleton']),
    },
    methods: {
      ...mapActions('Site', ['getSections']),
    },
    async mounted(){
      await this.getSections();
      this.keySlick = this.keySlick++;
    },
    data() {
        return {            
            slickOptions: {
                slidesToShow: 1,
                dots: true,
                autoplay: true,
                autoplaySpeed: 7000,                
            },
        };
    },
  }
</script>