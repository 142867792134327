<template>
	<div>
		<template v-if="partner && partner.working_hours && partner.working_hours.length > 0">
	    	<template v-if="checkIfOpened()">
	    		<div class="open-now"><i class="las la-door-open"></i>Aberto agora</div>
	    	</template>
	    	<template v-else>
	    		<div class="close-now"><i class="las la-door-closed"></i>Fechado agora</div>
	    	</template>
    	</template>
    	<div v-else class="open-now"></div>
    </div>
</template>
<script>
	export default{
		props: {
			partner: Object,
		},
		methods: {			
			checkIfOpened(){
				let dayNow = this.$moment().isoWeekday()+''; //1monday 7 sunday
				let hours = this.partner.working_hours[0];
				let day = hours[this.week[dayNow]];

				if(day.length == 0){
					return false;
				}

				let dayHours = day[0];

				let now = this.$moment();					
				let hourOpen = this.$moment((now.format('YYYY-MM-DD')+' '+dayHours.open+':00'));
				let hourClose = this.$moment((now.format('YYYY-MM-DD')+' '+dayHours.close+':00'));
				
				if(this.$moment().isAfter(hourOpen) && this.$moment().isBefore(hourClose)){
					return true;
				}else{
					return false;
				}
			},
		},
		mounted(){		
		},
		data(){
			return{				
				week: {
					'1': 'monday',
					'2': 'thursday',
					'3': 'wednesday',
					'4': 'tuesday',
					'5': 'friday',
					'6': 'saturday',
					'7': 'sunday',
				}
			}
		}
	}
</script>
<style scoped>
.open-now{
	margin-top: 5px;
	margin-bottom: 5px ;
}
.close-now{
	margin-top: 5px;
	margin-bottom: 5px;
}
</style>