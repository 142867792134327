<template>
	<div class="place-item layout-02 place-hover">
	   <div  class="place-inner">
	      <div v-if="partner.plan.permissions.indexOf('ads-logo') != -1 && partner.image_profile_path" 
	         class="place-thumb">
	         <a class="entry-thumb" :href="$root.getUrlPath('partner-details', {slug: partner.slug})">
	         <img :src="partner.image_profile_path" :alt="partner.name">
	         </a>
	         <a v-if="partner.plan && partner.plan.slug != 'plan-free' "
	         	 class="entry-category orange" href="javascript:;">
                <i class="las la-star"></i><span>Anunciante</span>
             </a>       
	      </div>
	      <div v-else class="place-thumb">
	         <img src="@/assets/images/semlogo.png">
	      </div>
	      <div class="entry-detail">
	         <div class="entry-head">
	            <div class="place-type list-item">
	               <span>{{ (partner.category ? partner.category.name : '') }}</span>
	            </div>
	            <div class="place-city">
	               <a href="#">{{ partner.city.name }}</a>
	            </div>
	         </div>
	         <h3 class="place-title" :class="{'view-home': options.fontHome}">
	            <a :href="$root.getUrlPath('partner-details', {slug: partner.slug})">{{ partner.name }}</a>
	         </h3>
	         <div v-if="options && options.showContact" class="place-contacts tooltip-box">
				 <div v-if="partner.address" style="margin-bottom: 5px;">
					 <b>Endereço:</b> <span v-html="partner.address +' - '+ partner.city.name"></span>
				 </div>
	            <div v-for="(contact, contactIndex) in partner.contacts" :key="contactIndex * 100">
	               <div v-if="contact.type == 'phone'" 
				   		class="item-blocked-button"
						style="margin-bottom: 3px;"
						@click="seeContact($event, contact, partner,contactIndex)">
						<a class="phone-blocked"
							:id="'phone_'+partner.id+'_'+contactIndex" 
							href="javascript:;">
							<i class="las la-phone"></i> {{ contact.value|VMask('(##) ####-####')  }}
						</a>
						<span
							:id="'span_'+partner.id+'_'+contactIndex" 
							 class="badge badge-warning pointer">clique para visualizar o número</span>
				   </div>
				   <div v-else-if="contact.type == 'mobile'" 
					class="item-blocked-button"
					style="margin-bottom: 3px;"
					@click="seeContact($event, contact, partner,contactIndex)">
						<a
							class="phone-blocked"
							:id="'phone_'+partner.id+'_'+contactIndex" 
							href="javascript:;">
						<i class="las la-mobile"></i> {{ contact.value|VMask('(##) #-####-####') }}
						</a>
						<span
							:id="'span_'+partner.id+'_'+contactIndex" 
							 class="badge badge-warning pointer">clique para visualizar o número</span>
					</div>
				   <div v-else-if="contact.type == 'whatsapp'" 
				   	class="item-blocked-button"
					   style="margin-bottom: 3px;"
					@click="seeContact($event, contact, partner,contactIndex)">
						<a
							class="phone-blocked"
							:id="'phone_'+partner.id+'_'+contactIndex" 
							href="javascript:;">
						<i class="lab la-whatsapp"></i> {{ contact.value|VMask('(##) #-####-####') }}
						</a>
						<span
							:id="'span_'+partner.id+'_'+contactIndex" 
							 class="badge badge-warning pointer">clique para visualizar o número</span>
				   </div>
	            </div>
	            <div class="open-now" 
               		style="color: orange; font-size: 14px;">
           			Quando ligar diga que viu o anúncio na Disk Certo Digital
           		</div>
	         </div>
	         <info-working-hours :partner="partner"></info-working-hours>
	         <div v-if="partner.has_rating" class="entry-bottom">
	            <div class="place-preview">
	               <div class="place-rating">
	                  <span>{{ partner.rating_score }}</span>
	                  <i class="la la-star"></i>
	               </div>
	               <span class="count-reviews">({{partner.rating_total}} Comentários)</span>				   
				   <a v-if="partner.facebook" :href="partner.facebook" target="_blank" style="margin-left: 10px">
					   <i class="fab fa-facebook" style="font-size: 20px;"></i>
				   </a>
				   <a v-if="partner.instagram" :href="partner.instagram" target="_blank" style="margin-left: 10px">
					   <i class="fab fa-instagram" style="font-size: 20px;"></i>
				   </a>
				   <a v-if="partner.youtube" :href="partner.youtube" target="_blank" style="margin-left: 10px">
					   <i class="fab fa-youtube" style="font-size: 20px;"></i>
				   </a>
				   <a v-if="partner.linkedin" :href="partner.linkedin" target="_blank" style="margin-left: 10px">
					   <i class="fab fa-linkedin" style="font-size: 20px;"></i>
				   </a>
				   <a v-if="partner.site" :href="partner.site" target="_blank" style="margin-left: 10px">
					   <i class="fas fa-globe" style="font-size: 20px;"></i>
				   </a>
	            </div>
	            <!-- <div class="place-price">
	               <span>$$</span>
	               </div> -->
	         </div>
	      </div>
	   </div>
	</div>
</template>
<script>
	import InfoWorkingHours from './InfoWorkingHours';
	import {mapActions} from 'vuex';

	export default{
		components: {
			InfoWorkingHours,
		},
		props: {
			partner: Object,
			options: Object,
		},
		methods:{
			...mapActions('Partner', ['searchList', 'setClickStats']),
			seeContact($event, contact, partner,contactIndex){
				let index = '#phone_'+partner.id+'_'+contactIndex;
				let spanIndex = '#span_'+partner.id+'_'+contactIndex;

				let linkTarget = document.querySelector(index);
				let spanTarget = document.querySelector(spanIndex);
				
				let contactValue = contact.value.length == 10 || contact.value.length == 11 ? `0${contact.value}` : contact.value;
				linkTarget.setAttribute('href', 'tel:'+contactValue);
				linkTarget.classList.remove('phone-blocked');
				
				spanTarget.remove();

				let params = {
					type: ('view-'+contact.type),
					value: (contact.value),
					partner_id: partner.id,
				}

				this.setClickStats(params);
			}
		}
	}
</script>
<style scoped>
.item-blocked-button{
	display: flex;
    align-items: center;
}
.site-main.layout-list .area-places .place-title {
	height: unset;
	margin-bottom: 5px;
}
.phone-blocked{
	color: #606060;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    width: 95px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.btn-show-more {
	margin-left: 15px;
}
.tooltip { 
width: 200px;
background: #59c7f9;
color: #ffffff;
text-align: center;
padding: 10px 20px 10px 20px;
border-radius: 5px;
top: 50%;
left: calc(100% + 11px);
transform: translate-y(-50%)
 }
.tooltip-box { 
position: relative
 }
.triangle { 
border-width: 0 6px 6px;
border-color: transparent;
border-bottom-color: #59c7f9;
position: absolute;
left: -6px;
top: calc(50% - 3px);
transform: rotate(270deg) translate-y(-50%)
 }

 .view-home{
 	font-size: 15px !important;
 	height: 30px;
 }
 .pointer {cursor: pointer;}
</style>