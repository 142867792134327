<template>
	<div>
		<template v-if="loading">
			<PuSkeleton style="height: 70px;"></PuSkeleton>
		</template>
		<template v-else-if="Object.keys(partner).length > 0 && partner.has_rating">
			<div class="place__box place__box--reviews">
			   <h3 class="place__title--reviews">
			      Avaliações ({{partner.rating_total}})
			      <span class="place__reviews__number">
			      	{{partner.rating_score}}		
			      	<i class="la la-star"></i>
			      </span>
			   </h3>
			   <ul class="place__comments">
			      <template v-if="Object.keys(ratings).length > 0 && ratings.data.length > 0">
				      <li v-for="(rating, index) in ratings.data" :key="index">
				         <div class="place__author">
				            <div class="place__author__avatar">
				               <a title="Avaliação" href="javascript:;">
				               	<img src="@/assets/images/assets/user-default.png" alt="image people">
				               </a>
				            </div>
				            <div class="place__author__info">
				               <a title="Sebastian" href="#">{{ rating.customer.name }}</a>
				               <div class="place__author__star">
				               		<i v-for="star in 5" 
				               			:key="star" 
				               			:class="{
					            			'las la-star': true, 
					            			'color-orange': (rating.rating >= star)
					            		}"></i>				                  
				               </div>
				               <span class="time">{{rating.created_at|moment('DD/MM/YYYY')}}</span>
				            </div>
				         </div>
				         <div class="place__comments__content">
				            <p v-html="rating.comment"></p>
				         </div>			         
				      </li>
			      </template>	
			      <template v-else>
			      	<li><i class="las la-star"></i> Seja o primeiro para avaliar</li>
			      </template>     
			   </ul>
			   <div v-if="successRating" class="alert alert-primary">
			   		<div class="">
			           <p>Avaliação salva com sucesso.</p>
			        </div>
			   </div>
			   <div v-else class="review-form">
			      <h3>Escrever avaliação</h3>
			      <form ref="formRating" @submit.prevent="handleSubmit">
			         <div class="rate">			            
			            <div class="stars">
			               <star-rating :star-size="30" v-model="starRating"></star-rating>
			            </div>
			         </div>
			         <div class="row" style="margin-bottom: 15px;">
			         	<div class="col-sm-4">
			         		<label>Nome</label>
			         		<input type="text" name="name" class="form-control" required>
			         	</div>
			         	<div class="col-sm-4">
			         		<label>E-mail</label>
			         		<input type="email" name="email" class="form-control" required>
			         	</div>
			         	<div class="col-sm-4">
			         		<label>Celular</label>			         		
			         		<the-mask name="mobile" :mask="['(##) #-####-####']" class="form-control" />
			         	</div>
			         </div>
			         <div class="field-textarea">
			            <img class="author-avatar" src="@/assets/images/assets/user-default.png" alt="author">
			            <textarea name="comment" placeholder="Escrever avaliação" required></textarea>
			         </div>
			         <div class="field-submit" style="margin-bottom: 15px; color: gray !important;">
			         	Ao enviar a sua avaliação você concorda com a nossa <a :href="$root.getUrlPath('politics')" target="_blank" rel="oppener"> política de privacidade</a>
			         </div>
			         <div class="field-submit">
			            <input type="submit" class="btn" value="Salvar avaliação">
			         </div>
			      </form>
			   </div>
			</div>
		</template>
	</div>
</template>
<script>
	import PartnerModel from '@/api/Partner';
	import StarRating from 'vue-star-rating';
	import {TheMask} from 'vue-the-mask'
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components:{
			StarRating,
			TheMask,
		},
		computed: {
			...mapGetters('Partner', ['loading', 'partner', 'ratings']),
		},
		methods:{
			...mapActions('Partner', ['partnerRatings']),
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),
			async handleSubmit(){
		        const self = this;

				if (this.$refs.formRating.checkValidity()) {
				  this.setLoading(true);
				  
				  let params = await this.$root.getAllData(this.$refs.formRating);
				  params.rating = this.starRating;
				  params.partner_id = this.partner.id;
				  
				  PartnerModel.addRating(params).then(response => {
				  	this.successRating = true;
				  	this.setLoading(false);
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formRating.reportValidity();
				}
		    },
		},
		mounted(){
			let params = {}
			params.slug = this.$route.params.slug;
			this.partnerRatings(params);
		},
		data(){
			return{
				starRating: 5,
				successRating: false,
			}
		}
	}
</script>
<style scoped>
	.color-orange{
		color: orange !important;
	}

	input[type=text],[type=email] {
		border: 1px solid #d2cece;
	}
</style>