<template>
	<div align="left">
		<h3>Dados Gerais</h3>
		<div class="row mb-3">
			<div class="col-sm-12">
				<label for="place_name">Nome da empresa</label>
				<input type="text" placeholder="Qual o nome da empresa?" v-model="form.name" name="name" required>
				<input v-if="('id' in form) && form.id" type="hidden" name="id" :value="form.id">
			</div>						
		</div>
		<div class="row mb-3">
			<div class="col-sm-12">
				<label>Descrição curta</label>
				<textarea name="description_short" v-model="form.description_short" class="form-control" rows="2"></textarea>
			</div>
		</div>
		<div class="field-group">
			<h4 class="label">Descrição longa da empresa</h4>
			<quill-editor ref="myQuillEditor" 
				:options="editorOption"
				v-model="form.description"
				@change="onEditorChange($event)">
				<div id="toolbar" slot="toolbar">
			        <!-- Add a bold button -->
			        <button class="ql-bold">Bold</button>
			        <button class="ql-italic">Italic</button>
			        <!-- Add font size dropdown -->
			        <select class="ql-size">
			          <option value="small"></option>
			          <!-- Note a missing, thus falsy value, is used to reset to default -->
			          <option selected></option>
			          <option value="large"></option>
			          <option value="huge"></option>
			        </select>
			        <select class="ql-font">
			          <option selected="selected"></option>
			          <option value="serif"></option>
			          <option value="monospace"></option>
			        </select>
			        <!-- Add subscript and superscript buttons -->
			        <button class="ql-script" value="sub"></button>
			        <button class="ql-script" value="super"></button>
			      </div>
			</quill-editor>
			<textarea name="description" v-model="form.description" style="display: none;"></textarea>
		</div>
		<div class="row mb-2">
			<div class="col-sm-12">
				<label>Categoria Principal</label>
				<select id="category_parent_id" ref="category_parent_id" name="category_parent_id" v-model="form.category.parent_id" @change="loadCategories('categories')" required>
					<option value="">---</option>
					<option v-for="(cat, index) in options.category_parent" :key="index" :value="cat.id">{{cat.name}}</option>
				</select>
			</div>
		</div>
		<div class="row mb-2">
			<div class="col-sm-12">
				<label>Categoria Secundária</label>
				<select name="category_id" v-model="form.category_id" required="true">
					<option value="">---</option>
					<option v-for="(cat, index) in options.categories" :key="index" :value="cat.id">{{cat.name}}</option>
				</select>
			</div>
		</div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import Site from '@/api/Site';
	import {mapGetters, mapActions} from 'vuex';
	import { quillEditor } from 'vue-quill-editor'

	export default{
		components: {
			quillEditor
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		watch: {
			user(promise){				
				this.form = promise;
			}
		},
		methods:{
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),
			onEditorChange({ quill, html, text }){			
				this.form.description = html
			},
			loadCategories(field){
				const self = this;

				let params = {};
				if(this.form.category.parent_id){					
					params.category_id = this.form.category.parent_id;
				}

				self.setLoading(true);
				Site.getCategories(params).then(response => {
					let data = response.data.data;
					
					self.options[field] = data;
					self.setLoading(false);
					
					if(field == 'category_parent'){						
						self.loadCategories('categories')
					}
				});
			}
		},
		mounted(){
			this.loadCategories('category_parent');

			if(Object.keys(this.user).length > 0){
				this.form = this.user;
			}
		},
		data(){
			return{
				editorOption: {
		          modules: {
		            toolbar: '#toolbar'
		          }
		        },
		        form: {
		        	name: '',
		        	category: {},
		        	category_id: '',
		        	description: '',
		        },
		        options: {
		        	category_parent: [],
		        	categories: [],
		        }
			}
		}
	}
</script>