<template>
	<main id="main" class="site-main contact-main">
		<div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-about.png);">
			<div class="container">
				<div class="page-title__content">
					<h1 class="page-title__name">Conecte-se</h1>
					<p class="page-title__slogan">Area Restrita para Parceiros</p>
				</div>
			</div>	
		</div><!-- .page-title -->
		<div class="site-content site-contact">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="contact-text">
							<h2>Seja um parceiro da Disk Certo Digital</h2>
							<div class="contact-box">								
								<p>
									Sendo nosso parceiro você tem uma infinidades de possibilidades, como métricas, resultados e informações em tempo real com tudo o que está acontecendo em nosso portal.
								</p>
								<p>
									Você ainda não cadastrou a sua empresa? Faça agora mesmo o registro.
								</p>
								<p align="center">
									<a :href="$root.getUrlPath('partner-register')" title="Get Direction">Quero cadastrar a minha empresa!</a>
								</p>
							</div>							
						</div>
					</div>
					<div class="col-md-6">
						<div class="contact-form">
							<h2>Conecte-se</h2>
							<form ref="formLogin" @submit.prevent="handleSubmit" class="form-underline">
								<div class="field-input">
									<input type="email" name="email" value="" placeholder="Email da sua conta">
								</div>
								<div class="field-input">
									<input type="password" name="password" placeholder="Senha de acesso">
								</div>
								<div class="field-submit mb-4">
									<input type="submit" value="Conectar" class="btn">
								</div>
								<a :href="$root.getUrlPath('partner-recover-pass')" title="Get Direction">Esqueceu a senha? Clique aqui.</a>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import {mapGetters, mapActions} from 'vuex';

	export default{
		methods:{
			...mapActions('Partner', ['login']),	
			async handleSubmit(){
		        const self = this;

				if (this.$refs.formLogin.checkValidity()) {
				  let params = await this.$root.getAllData(this.$refs.formLogin);				  
				  
				  self.login(params).then(response => {
				  	self.$router.push({name: 'partner-dashboard'})
				  });
				} else {
				  this.$refs.formLogin.reportValidity();
				}
		    },
		},
		data(){
			return{
				success: false,
			}
		}
	}
</script>