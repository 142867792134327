<template>
	<div class="member-menu">
		<div class="container">
			<ul>
				<li :class="{active: ($route.name == 'partner-dashboard')}">
					<router-link :to="{name: 'partner-dashboard'}">Dashboard</router-link>
				</li>
				<li :class="{active: ($route.name == 'partner-leads')}">
					<router-link :to="{name: 'partner-leads'}">Meus Leads</router-link>
				</li>
				<li :class="{active: ($route.name == 'partner-reviews')}">
					<router-link :to="{name: 'partner-reviews'}">Minhas avaliações</router-link>
				</li>
				<li :class="{active: ($route.name == 'partner-products')}">
					<router-link :to="{name: 'partner-products'}">Meus produtos</router-link>
				</li>
				<li :class="{active: ($route.name == 'partner-profile')}">
					<router-link :to="{name: 'partner-profile'}">Meu cadastro</router-link>
				</li>
				<li><a @click="logoff" href="javascript:;">Sair</a></li>
			</ul>
		</div>
	</div>
</template>
<script>
	export default{
		methods:{
			logoff(){
				localStorage.removeItem('disk_token');
				this.$router.push({name: 'partner-login'})
			}
		}
	}
</script>