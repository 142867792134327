<template>
	<div class="place-share">
		<!-- <a title="Save" href="#" class="add-wishlist">							
		<i class="la la-bookmark large"></i>
		</a> -->
		<a title="Share" href="#" class="share">									
		<i class="la la-share-square la-24"></i>
		</a>
		<div class="social-share">
		   <div class="list-social-icon"> 
		      <a class="facebook" @click="openPopup('https://www.facebook.com/sharer.php?u=')" 
		      	href="javascript:void(0)"> 
		      	<i class="fab fa-facebook-f"></i>
		      </a> 
		      <a class="twitter" 
		      	@click="openPopup('https://twitter.com/share?url=')"
		      	href="javascript:void(0)">
		      	<i class="fab fa-twitter"></i>
		      </a>
		      <a class="linkedin" 
		      	@click="openPopup('http://linkedin.com/shareArticle?mini=true&url=')"
		      	href="javascript:void(0)">
		      	<i class="fab fa-linkedin-in"></i>
		      </a> 
		      <a class="pinterest" 
		      	@click="openPopup('http://pinterest.com/pin/create/button/?url=')"
		      	href="javascript:void(0)">
		      	<i class="fab fa-pinterest-p"></i>
		      </a>
		   </div>
		</div>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex';
	import $ from 'jquery';

	export default{
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		},
		methods: {
			openPopup(social){
				var w = 300;
		        var h = 300;
		        var left = Number((screen.width/2)-(w/2));
		        var tops = Number((screen.height/2)-(h/2));

				let url = social +''+window.location.href;
				let params = `toolbar=0,status=0, width=${w},height=${h},top=${tops},left=${left}`;
				let pop = window.open(url,'sharer', params);
				pop.focus();
				return false;
			}
		},
		mounted(){
			this.$nextTick(() => {
				$( '.place-share .share' ).on( 'click', function(e) {
		            e.preventDefault();
		            $( this ).parents( '.place-share' ).find( '.social-share' ).fadeToggle();
		        });
			})
		}
	}
</script>