<template>
	<main id="main" class="site-main listing-main">
		<div class="listing-nav">
			<div class="listing-menu nav-scroll">
				<ul>
					<li class="active">
						<a href="#genaral" title="Genaral"><span class="icon"><i class="la la-cog"></i></span><span>Dados Gerais</span></a>
					</li>					
					<li><a href="#location" title="Location"><span class="icon"><i class="la la-map-marker"></i></span><span>Endereço</span></a></li>
					<li><a href="#contact" title="Contact info"><span class="icon"><i class="la la-phone"></i></span><span>Informações de Contato</span></a></li>					
					<li><a href="#open" title="Open hourses"><span class="icon"><i class="la la-business-time"></i></span><span>Horário de Funcionamento</span></a></li>
					<li><a href="#media" title="Media"><span class="icon"><i class="la la-image"></i></span><span>Imagens</span></a></li>
				</ul>
			</div>
		</div><!-- .listing-nav -->
		<div class="listing-content">			
			<form-global />
		</div><!-- .listing-content -->
	</main>
</template>
<script>
	import FormGlobal from './Form';

	export default{
		components: {
			FormGlobal			
		},		
	}
</script>