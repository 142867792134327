<template>
	<div>
		<h3>Imagens</h3>
		<div class="field-group field-file">
			<label for="image_cover">Imagem de Capa</label>
			<label for="image_cover" class="preview">
				<input type="file" ref="image_cover" id="image_cover" name="image_cover" class="upload-file" data-max-size="700000">
				<img v-if="form.image_cover_path" :src="form.image_cover_path" class="img_preview">
				<img v-else class="img_preview" src="@/assets/images/no-image.png" alt="" />				
				<i class="la la-cloud-upload-alt"></i>
			</label>
			<div class="field-note">Tamanho máximo 700kb - Dimensões 1440x700 - Para exibir essa imagem é necessário ter o plano intermedíario ativo.</div>
		</div>
		<div class="field-group field-file">
			<label for="image_profile">Logo</label>
			<label for="image_profile" class="preview">
				<input type="file" ref="image_profile" id="image_profile" name="image_profile" class="upload-file" data-max-size="700000">
				<img v-if="form.image_profile_path" :src="form.image_profile_path" class="img_preview">
				<img v-else class="img_preview" src="@/assets/images/no-image.png" alt="" />
				<i class="la la-cloud-upload-alt"></i>
			</label>
			<div class="field-note">Tamanho máximo 700kb - Dimensões 600x600 - Para exibir essa imagem é necessário ter o plano intermedíario ativo.</div>
		</div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		methods: {
			initImages(){
				function readURL(input, _this) {
		            if (input.files && input.files[0]) {
		                var reader = new FileReader();
		        
		                reader.onload = function (e) {
		                    _this.parents( '.field-group' ).find( '.img_preview' ).attr('src', e.target.result);
		                }
		                reader.readAsDataURL(input.files[0]);
		            }
		        }
		        
		        $( '.field-group input[type="file"]' ).change(function () {
		        	var _this = $( this );
		            readURL(this, _this);
		        });
		        
		        var fileInput = $('.upload-file');
			}
		},
		computed:{
			...mapGetters('Partner', ['user']),
		},		
		mounted(){
			this.$nextTick(() => {
				setTimeout(() => {
					this.initImages();

					if(Object.keys(this.user).length > 0){
						this.form = this.user;
					}
				},1000);
			});
		},
		data(){
			return{
				form:{}
			}
		}
	}
</script>