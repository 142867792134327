<template>
	<div>
		<h3>Endereço</h3>
		<div class="row mb-3">
			<div class="col-sm-6">
				<label for="place_address">Estado</label>
				<select name="state_id" v-model="form.state_id" @change="getCities" required>
					<option value="">----</option>
					<option v-for="(state, index) in options.states"
						:key="index" :value="state.id">{{ state.name }}</option>
				</select>
			</div>
			<div class="col-sm-6">
				<label for="place_address">Cidade</label>
				<select name="city_id" v-model="form.city_id" required>
					<option value="">----</option>
					<option v-for="(state, index) in options.cities"
						:key="index" :value="state.id">{{ state.name }}</option>
				</select>
			</div>
		</div>
		<div class="row mb-3">
			<div class="col-sm-6">
				<label for="place_address">Endereço</label>
				<input type="text"  placeholder="Endereço completo" v-model="form.address"
				name="address"
				@blur="geocoder" required>
				<input type="hidden" name="lat" v-model="form.lat">
				<input type="hidden" name="lng" v-model="form.lng">
			</div>
			<div class="col-sm-6">
				<label for="place_address">Complemento</label>
				<input type="text" v-model="form.address_complement" placeholder=""
				name="address_complement">				
			</div>
		</div>
		<div class="row mb-3">			
			<div class="col-sm-6">
				<label for="place_address">Código Postal</label>
				<the-mask name="postal_code" v-model="form.postal_code" :mask="['#####-###']" placeholder="CEP" />
			</div>
		</div>
		<div class="field-group field-maps">
			<div class="field-inline">
				<label for="pac-input">Ative a geolocalição do site para arrastar o icone para a região correta</label>
			</div>			
			<div class="field-map">
				<l-map 
					:key="key"
		    		:zoom="zoom" 
		    		:center="center"
		    		style="height: 200px; width: 100%; margin: 0; padding: 0;">
			      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>					      
			      <l-marker 
			      	:lat-lng="center" 					      	
			      	:draggable="true"
			      	 @drag="updateMap" 
			      	:icon="icon"></l-marker>
			    </l-map>
			</div>
		</div>
	</div>
</template>
<script>
	import Site from '@/api/Site';
	import {TheMask} from 'vue-the-mask';
	import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
	import { latLng, icon } from "leaflet";	
	import {mapGetters, mapActions} from 'vuex';	

	export default {		
		components: {
			LMap,
			LTileLayer,
			LMarker,
			TheMask,			
		},
		computed:{
			...mapGetters('Partner', ['user']),
		},
		methods: {
			updateMap: function(event){
				const self = this;

				let position = event.target.getLatLng();
				self.form.lat = position.lat;
				self.form.lng = position.lng;
			},
			initMap(){
				navigator.geolocation.getCurrentPosition(pos => {
			      this.center = latLng(pos.coords.latitude, pos.coords.longitude);
			      this.form.lat = pos.coords.latitude;
				  this.form.lng = pos.coords.longitude;
			    }, err => {})
			},
			getStates(){
				const self = this;
				Site.getStates().then(response => {
					let data = response.data.data;
					
					self.options.states = data;

					if(self.form.state_id){
						self.getCities();
					}				
				});
			},
			getCities($event){
				const self = this;
				let params = {};
				params.state_id = self.form.state_id;

				Site.getCities(params).then(response => {
					let data = response.data.data;
					
					self.options.cities = data;					
				});
			},
			geocoder(){
				return;
				const self = this;

				let elState = document.querySelector("select[name=state_id]");
				let state = elState.options[elState.selectedIndex].text;

				let elCity = document.querySelector("select[name=city_id]");
				let city = elCity.options[elCity.selectedIndex].text;

				let address = this.form.address+' - '+city+', '+state;
				let proximity = `${this.form.lat},${this.form.lng}`;
				Site.geocoder(address, this.token, proximity).then(response => {
					let data = response.data;

					let pos = data.features[0].center;					
					self.center = latLng(pos[0], pos[1]);
					self.key = (new Date().getTime());
				});
			}
		},
		mounted(){
			this.initMap();
			this.getStates();

			if(Object.keys(this.user).length > 0){
				this.form = this.user;
				this.center = latLng(this.user.lat, this.user.lng);				
			}
		},
		data() {
		  return {
		  	key: (new Date().getTime()),
		  	form:{
		  		address: '',
		  		lat: '',
		  		lng: '',
		  	},
		  	options: {
		  		states: [],
		  		cities: [],
		  	},
		  	token: 'pk.eyJ1Ijoicm9uaWNlYyIsImEiOiJja2xham42N3EwNjVnMm9zNGx2bHk3MGZhIn0.RnmAWn0-x0cB8ESinLgJOg',
		  	optionsDynamic: {},
		  	url:'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      		attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      		icon: icon({
	            iconUrl: require('../../../../node_modules/leaflet/dist/images/marker-icon.png'),
	            iconSize: [26, 36],
	            // iconAnchor: [30, 37]
	        }),
	        center: latLng(-15.592512, -56.074827),
	        zoom: 17,
		  };
		},
	}
</script>