<template>
	<div class="sidebar sidebar-shadow sidebar-sticky">
	   <template v-if="loading">
	   		<PuSkeleton style="height: 200px" />
	   </template>
	   <template v-else-if="Object.keys(partner).length > 0">
		   <aside class="widget widget-sb-detail">
		      <div class="widget-top">
		         <div class="flex">
		            <store-detail></store-detail>		          
		         </div>
		      </div>
		      <div v-if="partner.lat" class="map-detail">
		         <l-map
				      :zoom="zoom"
				      :center="center"
				      style="height: 250px;">
				      <l-tile-layer
				        :url="url"
				        :attribution="attribution"></l-tile-layer>      
				      <!-- Use icon given in icon property -->
				      <l-marker			        			       
				        :lat-lng="latLng"
				        :icon="icon">
				      </l-marker>
				    </l-map>  		         
		      </div>
		      <div class="business-info">
		         <h4>Mais informações</h4>
		         <ul>
		            <li>
		            	<i class="las la-map-marked-alt large"></i> 
		            	<span v-html="partner.address"></span>
		            </li>
		            <li v-if="partner.email">
		            	<i class="las la-envelope"></i><a :href="'mailto:'+partner.email">{{partner.email}}</a>
		            </li>	            
		            <li v-if="partner.site">
		            	<i class="la la-globe large"></i>
		            	<a :href="partner.site" target="_blank">{{ partner.site }}</a>
		            </li>
					<li v-if="partner.facebook">
		            	<i class="la la-facebook large"></i>
		            	<a :href="partner.facebook" target="_blank">Ver página Facebook</a>
		            </li>
					<li v-if="partner.instagram">
		            	<i class="la la-instagram large"></i>
		            	<a :href="partner.instagram" target="_blank">Ver página Instagram</a>
		            </li>
					<li v-if="partner.linkedin">
		            	<i class="la la-linkedin large"></i>
		            	<a :href="partner.linkedin" target="_blank">Ver página LinkedIn</a>
		            </li>
					<li v-if="partner.youtube">
		            	<i class="la la-youtube large"></i>
		            	<a :href="partner.youtube" target="_blank">Ver canal Youtube</a>
		            </li>
		         </ul>	         
		      </div>
		   </aside>
	   </template>
	   <!-- .widget-reservation -->
	</div>
</template>
<script>	
	import StoreDetail from './StoreDetail';
	import {mapGetters} from 'vuex';	
	import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
	import { latLng, icon } from "leaflet";		

	export default{
		components: {
			StoreDetail,
			LMap,
		    LTileLayer,
		    LMarker,
		},
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		},
		watch: {
			partner(promise){				
				this.center = latLng(promise.lat, promise.lng);
				this.latLng = latLng(promise.lat, promise.lng);
			}
		},
		mounted(){			
		},
		data(){
			return{
				title: "Mapa cidade",
				zoom: 16,
				latLng: latLng(-15.592512, -56.074827),
				center: latLng(-15.592512, -56.074827),
				url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
				attribution:
				'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				// icon: ,
				staticAnchor: [16, 37],
				icon: icon({
					iconUrl: require('../../../../node_modules/leaflet/dist/images/marker-icon.png'),
					iconSize: [25, 32],
					iconAnchor: [16, 16]
				}),
				iconSize: 64,				
			}
		}
	}
</script>