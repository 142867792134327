<template>
	<div>
		<template v-if="loading">
			<PuSkeleton style="height: 30px" />
			<div class="place__box place__box--npd">
				<PuSkeleton style="height: 50px" />
			</div>
		</template>
		<template v-else>
			<ul v-if="partner.tags && partner.tags.length > 0"
				class="place__breadcrumbs breadcrumbs">
			   <li v-for="tag in partner.tags" :key="tag">
			   	<a :title="tag" href="javascript:;">{{ tag }}</a>
			   </li>			   
			</ul>
			<!-- .place__breadcrumbs -->
			<div class="place__box place__box--npd" style="margin-bottom: 20px !important;">
			   <h1>{{partner.name}}</h1>
			   <div class="place__meta">
			      <div class="place__reviews reviews">
			         <span class="place__reviews__number reviews__number">
			         	{{partner.rating_score}}							
			         	<i class="la la-star"></i>
			         </span>
			         <span class="place__places-item__count reviews_count">({{partner.rating_total}} avaliações)</span>
			      </div>
			      <!-- <div class="place__currency">$$</div> -->
			      <div v-if="partner.category" class="place__category">
			         <a 
			         	:title="partner.category.name" 
			         	:href="$root.getUrlPath('category-list', {categorySlug: partner.category.slug})">
			         	{{ partner.category.name }}
			         </a>			         
			      </div>
			   </div>
			   <!-- .place__meta -->
			</div>
		</template>
	</div>
</template>
<script>
	import {mapGetters} from 'vuex';

	export default{		
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		}
	}
</script>