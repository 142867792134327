<template>
	<div class="container">
		<div v-if="Object.keys(user).length > 0" class="member-wrap">
			<div class="member-wrap-top">
				<h2>Bem vindo, {{ user.name }}.</h2>
				<template v-if="user.plan">
					<p v-if="user.plan_id">
						Seu plano atual é <b>{{ user.plan.name }}</b>.
					</p>
					<p v-else>
						Seu plano atual é <b>{{ user.plan.name }}</b>
					</p>
				</template>
			</div><!-- .member-wrap-top -->
			<template v-if="user.is_active == 0">
				<div  class="upgrade-box" style="background-color: #ffa84f">
					<h1>Cadastro em processo de validação.</h1>
					<p>Estamos validando o seu cadastro. Em breve você receberá a resposta.</p>
				</div>
			</template>
			<template v-else>
				<div v-if="user.plan && user.plan.id == 4" class="upgrade-box">
					<h1>Ganhe mais funcionalidades.</h1>
					<p>Atualize o seu {{user.plan.name}} para um mais avançado e tenha mais funcionalidades disponíveis.</p>
					<router-link :to="{name: 'plans'}" class="btn" title="Upgrade now">Atualizar agora</router-link>
					<img src="@/assets/images/assets/img-people.svg" alt="Upgrade now">				
				</div>
			</template>
			<div class="member-statistical">
				<div class="row">
					<div class="col-sm-12">
						<p>
							Métricas do período <b>{{ date.start}}</b> até <b>{{ date.end }}</b> 
						</p>
					</div>
					<div class="col-lg-3 col-6">
						<div class="item blue">
							<h3>Cliques em telefones</h3>
							<span class="number">{{ charts.clicks_phone }}</span>
							<span class="line"></span>
						</div>
					</div>
					<div class="col-lg-3 col-6">
						<div class="item green">
							<h3>Visualizações na Página</h3>
							<span class="number">{{ charts.clicks_page }}</span>
							<span class="line"></span>
						</div>
					</div>
					<div class="col-lg-3 col-6">
						<div class="item yellow">
							<h3>Total de Leads</h3>
							<span class="number">{{ charts.lead_total }}</span>
							<span class="line"></span>
						</div>
					</div>
					<div class="col-lg-3 col-6">
						<div class="item purple">
							<h3>Total de Avaliações</h3>
							<span class="number">{{ charts.rating_total }}</span>
							<span class="line"></span>
						</div>
					</div>
				</div>
			</div><!-- .member-statistical -->
			<div class="owner-box">
				<div class="row">
					<div class="col-lg-6">						
						<div class="ob-item">
							<div class="ob-head">
								<h3>Últimos Leads</h3>
								<router-link :to="{name: 'partner-leads'}" class="view-all" title="View All">Ver todos</router-link>
							</div>
							<template v-if="user.plan && user.plan.permissions.indexOf('ads-leads') != -1">
								<div class="ob-content">
									<list-leads :leads="charts.leads"></list-leads>
								</div>
							</template>
							<template v-else>
								<div class="blocked-text">
									Atualize o seu plano para visualizar os contatos.
									<router-link :to="{name: 'plans'}" class="btn" title="Upgrade now">Atualizar agora</router-link>
								</div>
								<div class="ob-content blocked">								
									<list-leads :leads="charts.leads"></list-leads>
								</div>
							</template>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="ob-item">
							<div class="ob-head">
								<h3>Últimas avaliações</h3>
								<router-link :to="{name: 'partner-reviews'}" class="view-all" title="View All">Ver todos</router-link>
							</div>
							<div class="ob-content">
								<list-reviews :ratings="charts.ratings"></list-reviews>
							</div>
						</div>
					</div>				
				</div>
			</div><!-- .owner-box -->
		</div><!-- .member-wrap -->
	</div>
</template>
<script>
	import ListReviews from './ListReviews';
	import ListLeads from './ListLeads';
	import Partner from '@/api/Partner';
	import {mapGetters} from 'vuex';

	export default{
		components:{
			ListLeads,
			ListReviews,
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		methods: {
			init(){
				const self = this;
				Partner.dashboard().then(response => {
					let data = response.data.data;
					self.charts = data;					
				});
			}
		},
		mounted(){
			this.init();

			this.date.start = this.$moment().clone().startOf('month').format('DD/MM/YYYY');
			this.date.end   = this.$moment().clone().endOf('month').format('DD/MM/YYYY');
		},
		data(){
			return {
				date: {},
				charts: {},
			}
		}
	}
</script>
<style scoped>
	.blocked{
	  /* Add the blur effect */
	  filter: blur(8px);
	  -webkit-filter: blur(8px);

	  /* Full height */
	  height: 100%;
	}
	.blocked-text {
		z-index: 9999;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
</style>