<template>
	<ul>
		<template v-if="leads && leads.length > 0">
			<li v-for="(lead, index) in leads" :key="index" :class="lead.lead_status.html_class">
				<p class="date"><b>Data:</b>{{ lead.created_at|moment("DD/MM/YYYY HH:mm") }}</p>
				<p class="place"><b>Cliente:</b>{{ lead.customer.name }}</p>
				<p class="status"><b>Status:</b><span>{{ lead.lead_status.name }}</span></p>
				<router-link :to="{name: 'partner-leads'}" title="More" class="more"><i class="las la-angle-right"></i></router-link>
			</li>
		</template>
		<template v-else>
			<li>
				Nenhum lead recebido até o momento.
			</li>
		</template>		
	</ul>
</template>
<script>
	export default{
		props:{
			leads: [Array, Object],
		}
	}
</script>
<style scoped>
	.ob-item .ob-content li.waiting span {
	    color: #ffcc00 !important;
	}

	.ob-item .ob-content li.processing span {
	    color: orange !important;
	}

	.ob-item .ob-content li.success span {
	    color: #2dcb48 !important;
	}

	.ob-item .ob-content li.trash span {
	    color: #e74807 !important;
	}
</style>