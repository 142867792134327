<template>
	<div>
		<h3>Horário de funcionamento</h3>
		<div class="field-group">
			<label for="place_email">Informe o horário de funcionamento</label>
			<div class="field-accordion">
				<div v-for="(date, index) in dates" :key="index" class="accordion-item">
					<div class="accordion-top">
						<label :for="date.short">
							{{ date.name }}
						</label>							
					</div>
					<div class="accordion-bot">
						<div class="row">
							<div class="col-sm-6">								
								<the-mask :name="`working_hours[0][${date.short}][0][open]`" :mask="['##:##']" placeholder="00:00" :value="hours(date.short, 'open')" />
							</div>
							<div class="col-sm-6">
								<the-mask :name="`working_hours[0][${date.short}][0][close]`" :mask="['##:##']" :value="hours(date.short, 'close')" placeholder="00:00" />
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>
<script>
	import {TheMask} from 'vue-the-mask';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components: {
			TheMask,
		},
		computed:{
			...mapGetters('Partner', ['user']),			
		},
		methods:{
			hours(day, hour){
				if(Object.keys(this.user).length > 0 && this.user.working_hours.length > 0){
					let param = this.user.working_hours[0];
					let dayFull = param[day];
					
					if(dayFull.length > 0){
						let hourFull = dayFull[0];
						return hourFull[hour];
					}
				}

				return '';
			}
		},
		mounted(){
			if(Object.keys(this.user).length > 0){
				this.form = this.user;
			}
		},
		data(){
			return {
				form:{},
				dates: [
					{
						name: 'Segunda-feira',
						short: 'monday',
					},
					{
						name: 'Terça-feira',
						short: 'tuesday',
					},
					{
						name: 'Quarta-feira',
						short: 'wednesday',
					},
					{
						name: 'Quinta-feira',
						short: 'thursday',
					},
					{
						name: 'Sexta-feira',
						short: 'friday',
					},
					{
						name: 'Sabado',
						short: 'saturday',
					},
					{
						name: 'Domingo',
						short: 'sunday',
					},
				],
			}
		}
	}
</script>
