<template>
	<main id="main" class="site-main contact-main">
		<div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-about.png);">
			<div class="container">
				<div class="page-title__content">
					<h1 class="page-title__name">Fale conosco</h1>
					<p class="page-title__slogan">Está com alguma dúvida, entre em contato conosco!</p>
				</div>
			</div>	
		</div><!-- .page-title -->
		<div class="site-content site-contact">
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<div class="contact-text">
							<h2>Nosso endereço</h2>
							<div class="contact-box">
								<h3>Cáceres - MT</h3>
								<p>Rua da Maravilha, N740 - Cavalhada</p>
								<p>(65) 3223-9002</p>
								<a href="https://www.google.com/maps/dir//diskcertodigital/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x939a557ded7ea387:0xfd880b9e7fe5c351?sa=X&ved=2ahUKEwjJ7tvm5_DuAhXRSBUIHQGsBqEQ9RcwFHoECCYQBQ" title="Get Direction">Direções</a>
							</div>
							<div class="contact-box">
								<h3>Mirassol</h3>
								<p>Rua Maria dos Anjos Braga - CENTRO</p>
								<p>(65) 3241-4009 / (65) 99977-4009 /  (65) 99978-4009</p>
								<a href="https://www.google.com/maps/dir//diskcertodigital/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x939a557ded7ea387:0xfd880b9e7fe5c351?sa=X&ved=2ahUKEwjJ7tvm5_DuAhXRSBUIHQGsBqEQ9RcwFHoECCYQBQ" title="Get Direction">Direções</a>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div v-if="successLead">
							<div class="alert alert-success">
								<h2>Sucesso :)</h2>
								<p>
									<i class="la la-check-circle"></i>
									Contato enviado com sucesso, em breve estaremos entrando em contato.
								</p>
							</div>
						</div>
						<div v-else class="contact-form">
							<h2>Enviar mensagem</h2>
							<form ref="formLead" @submit.prevent="handleSubmit" class="form-underline">
								<div class="field-input">
									<input type="text" placeholder="Seu nome*" value="" name="name" required>
								</div>
								<div class="field-input">
									<input type="email" placeholder="Seu e-mail" value="" name="email">
								</div>
								<div class="field-input">
									<the-mask name="mobile" :mask="['(##) #-####-####']" placeholder="Seu celular*" />
								</div>
								<div class="field-input">
									<input type="text" placeholder="Assunto de interesse?*" ref="subject" value="" name="subject">
								</div>
								<div class="field-input">
									<textarea name="message" placeholder="Mensagem para empresa*" required></textarea>
								</div>
								<div class="field-input" style="margin-bottom: 10px;">
									Ao solicitar o contato você concorda com a nossa
									<a :href="$root.getUrlPath('politics')" target="_blank" rel="oppener"> política de privacidade</a>
								</div>
								<div class="field-submit">
									<input type="submit" value="Enviar mensagem" class="btn">
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import Site from '@/api/Site';	
	import {mapGetters, mapActions} from 'vuex';
	import {TheMask} from 'vue-the-mask'

	export default{
		components:{
			TheMask,
		},
		computed: {		
		},
		methods: {		
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),	
			async handleSubmit(){
		        const self = this;

				if (this.$refs.formLead.checkValidity()) {
				  let params = await this.$root.getAllData(this.$refs.formLead);				  

				  this.setLoading(true);
				  Site.saveLeadContact(params).then(response => {
				  	this.successLead = true;
				  	this.setLoading(false);
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formLead.reportValidity();
				}
		    },
		},
		mounted(){			
			this.$refs.subject.value = this.$route.query.origin;
		},
		data(){
			return{
				successLead: false,
			}
		}
	}
</script>