<template>
	<main v-if="postDetail" id="main" class="site-main">
		<div class="page-title page-title--small page-title--blog align-left" 
	   style="background-color: rgb(0, 152, 218); margin-bottom: 1px !important;">
	      <div class="container">
	         <div class="page-title__content">
	            <h1 class="page-title__name">{{post.name}}</h1>	            
	         </div>
	      </div>
	   </div>
		<div class="blog-content">
			<div class="container">
				<div class="row">
					<div class="col-lg-9">
						<div class="blog-left">
							<ul v-if="post && post.tags.length > 0" class="breadcrumbs">
								<li v-for="tag in post.tags" :key="tag">
									<a :title="tag" href="#">{{tag}}</a></li>
							</ul><!-- .breadcrumbs -->
							<div class="entry-content">
								<h1>{{post.name}}</h1>
								<ul class="entry-meta">
									<li>
										by <a :title="post.user.first_name" href="#">
										{{ post.user.first_name }} {{ post.user.last_name }}
										</a>
									</li>
									<li>{{ post.created_at|moment("DD/MM/YYYY") }}</li>
									<!-- <li>3 comments</li> -->
								</ul>
								<div class="entry-desc" v-html="post.description">
								</div><!-- .entry-desc -->
								<div class="entry-author">
									<img :src="post.user.avatar_path" :alt="post.user.first_name">
									<div class="author-info">
										<div class="author-title">
											<a title="Nitithorn" href="#">{{post.user.first_name}} {{post.user.last_name}}</a>										
										</div>
										<div class="author-desc"><p>O guia mais completo de endereços, telefones, locais, serviços do Mato Grosso - DiskCerto Digital</p></div>
									</div>
								</div><!-- .entry-author -->
							</div><!-- .entry-content -->
							<div v-if="related && related.length > 0" class="related-post">
								<h2>Últimas publicações</h2>
								<div class="related-grid columns-3">
									<article v-for="postRelated in related"
										:key="postRelated.id"
										class="hover__box post">
										<div v-if="postRelated.image_path" class="post__thumb hover__box__thumb">
											<a :href="$root.getUrlPath('blog-details', {slug: postRelated.slug})">
												<img v-lazy="postRelated.image_path" :alt="postRelated.name"></a>
										</div>
										<div class="post__info">
											<ul v-if="postRelated.tags && postRelated.tags.length > 0" class="post__category">
							                    <li v-for="tag in postRelated.tags" :key="tag">
						                          <a href="javascript:;">{{ tag }}</a>
						                        </li>                          
							                  </ul>
							                  <h3 class="post__title">
							                  	<a :title="postRelated.name"
							                  	:href="$root.getUrlPath('blog-details', {slug: postRelated.slug})">
							                  		{{postRelated.name}}
							                  	</a>
							                  </h3>
										</div>
									</article>									
								</div>
							</div><!-- .related-post -->
							<div class="place__box place__box--reviews entry-comment">
								<h3 class="place__title--reviews">Comentários</h3>
								<div class="fb-comments" 
									:data-href="url" 
									data-width="100%" 
									data-numposts="8"></div>
							</div><!-- .place__box -->
						</div><!-- .place__left -->
					</div>
					<div class="col-lg-3">
						<div class="sidebar sidebar--shop sidebar--border">
							<aside class="sidebar--shop__item widget">
								<h3 class="sidebar--shop__title">Best seller</h3>
								<div class="sidebar--shop__content">
									<div class="product-small">
										<div class="product-small__thumb">
											<a title="Mongolia" href="#"><img src="images/shop/product-07.jpg" alt="Mongolia"></a>
										</div>
										<div class="product-small__info">
											<h3><a title="Monggolia Travel Guide" href="#">Monggolia Travel Guide</a></h3>
											<div class="product-small__price">Price: <span>$59</span></div>
										</div>
									</div>
									<div class="product-small">
										<div class="product-small__thumb">
											<a title="Turkey" href="#"><img src="images/shop/product-05.jpg" alt="Turkey"></a>
										</div>
										<div class="product-small__info">
											<h3><a title="Vietnam Travel Guide" href="#">Vietnam Travel Guide</a></h3>
											<div class="product-small__price">Price: <span>$44</span></div>
										</div>
									</div>
									<div class="product-small">
										<div class="product-small__thumb">
											<a title="India" href="#"><img src="images/shop/product-02.jpg" alt="India"></a>
										</div>
										<div class="product-small__info">
											<h3><a title="India Travel Guide" href="#">India Travel Guide</a></h3>
											<div class="product-small__price">Price: <span>$35</span></div>
										</div>
									</div>
									
								</div>
							</aside><!-- .sidebar--shop__item -->
							
						</div><!-- .sidebar -->
					</div>
				</div>
			</div>
		</div><!-- .blog-content -->
	</main>
</template>
<script>      
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'BlogDetail',
    components: {       
    },
    computed: {
      ...mapGetters('Blog', {
      	postDetail: 'post',
      	loading: 'loading',
      }),
      post(){               
      	return this.postDetail.post;
      },
      related(){
      	return this.postDetail.related;
      }
    },
    methods: {
      ...mapActions('Blog', ['getPostDetail']),
    },
    mounted(){
    	let params = {};
    	if(this.$route.params.slug){
    		params.slug = this.$route.params.slug;
    	}
    	this.getPostDetail(params);
    	this.url = window.location.href;
    },
    data() {
        return {          
        	url: '',
        };
    },
  }
</script>