<template>
	<main id="main" class="site-main">
		<div class="site-content owner-content">
			<restrict-menu></restrict-menu>

			<div class="container">
				<div class="member-place-wrap">
					<div class="member-wrap-top">
						<h2 v-if="params.id">Alterar Produto</h2>
						<h2 v-else>Novo Produto</h2>
					</div><!-- .member-wrap-top -->
					<div class="member-place-list owner-booking" style="padding: 20px;">
						<form id="formProduct" ref="formProduct" @submit.prevent="handleSubmit">
							<input v-if="params.id" type="hidden" name="id" :value="params.id">
							<div class="row">
								<div class="col-sm-4 mb-3">
									<label>Nome produto</label>
									<input type="text" name="name" v-model="params.name" class="form-control">
								</div>
								<div class="col-sm-4 mb-3">
									<label>SKU (Código Produto)</label>
									<input type="text" name="sku" v-model="params.sku" class="form-control">
								</div>
								<div class="col-sm-4 mb-3">
									<label>Ativo</label>
									<select name="is_active" class="form-control" v-model="params.is_active">
										<option value="1">Ativo</option>
										<option value="0">Desativado</option>
									</select>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-4 mb-3">
									<label>Categoria</label>
									<input type="text" name="category" v-model="params.category" class="form-control">
									<span class="help-block">Permite agrupar produtos em uma mesma categoria</span>
								</div>
								<div class="col-sm-4 mb-3">
									<label>Preço R$</label>
									<input type="number" name="price" v-model="params.price" class="form-control" step="0.01">
								</div>								
							</div>
							<div class="row">
								<div class="col-sm-12 mb-3">
									<label>Pequena descrição</label>
									<textarea name="description" class="form-control" rows="3" v-model="params.description"></textarea>
								</div>								
							</div>
							<div class="row">
								<div class="col-sm-4 field-group field-file">
									<label for="images">Imagem</label>
									<label for="images" class="preview">
										<input type="file" ref="images" id="images" name="images" class="upload-file" data-max-size="700000">
										<img class="img_preview" v-if="params.images.length > 0" :src="params.images_path[0]" />
										<img v-else class="img_preview" src="@/assets/images/no-image.png" alt="" />
										<i class="la la-cloud-upload-alt"></i>
									</label>
									<div class="field-note">Tamanho máximo 700kb - Dimensões 600x600</div>
								</div>							
							</div>
							<div class="field-group field-submit" align="center">
								<input type="submit" name="submit" value="Salvar dados" class="btn" style="width: 150px">
							</div>
						</form>
					</div>
			        <!--  -->
				</div><!-- .member-place-wrap -->
			</div>
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import $ from 'jquery';
	import RestrictMenu from '../Menu';
	import Partner from '@/api/Partner';
	import {mapGetters, mapActions} from 'vuex';

	export default{
		components:{
			RestrictMenu
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		methods: {
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),
			initImages(){
				function readURL(input, _this) {
		            if (input.files && input.files[0]) {
		                var reader = new FileReader();
		        
		                reader.onload = function (e) {
		                    _this.parents( '.field-group' ).find( '.img_preview' ).attr('src', e.target.result);
		                }
		                reader.readAsDataURL(input.files[0]);
		            }
		        }
		        
		        $( '.field-group input[type="file"]' ).change(function () {
		        	var _this = $( this );
		            readURL(this, _this);
		        });
		        
		        var fileInput = $('.upload-file');
			},
			async validFiles(){
				var fileInput = $('.upload-file');
				var maxSize = fileInput.data('max-size');

				if(fileInput.get(0).files.length){
	                var fileSize = fileInput.get(0).files[0].size; // in bytes

	                if(fileSize>maxSize){
	                    alert('Existe um arquivo maior que ' + maxSize + ' kb');
	                    return false;
	                }
	            }

	            return true;
			},
			async handleSubmit(){
				let checkFile = await this.validFiles();

				if(!checkFile){
					return;
				}

				let params = $("#formProduct").serializeObject();
				let formData = new FormData();

				$.each(params, function(index, value){
					formData.append(index, value);				
				});

				var fileInput = $('.upload-file');
				if(fileInput.get(0).files.length){					
					formData.append('images', fileInput.get(0).files[0]);
				}
			
				if (this.$refs.formProduct.checkValidity()) {
				  this.setLoading(true);
				  Partner.productRegisterUpdate(formData).then(response => {				  	
				  	this.setLoading(false);
				  	this.$fire({
					  title: "Sucesso",
					  text: 'Registro feito com sucesso',
					  type: "success",			  
					}).then(r => {						
					});
				  	this.$router.push({name: 'partner-products'});
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formProduct.reportValidity();
				}
			},
			loadInfo(){
				const self = this;				
				let params = {
					id: this.$route.params.id,
				}

				this.setLoading(true)
				Partner.productEdit(params).then(response => {
					let data = response.data.data;
					self.params = data;
					this.setLoading(false)
				});
			},
		},
		mounted(){	
			if(this.$route.params.id){
				this.loadInfo();
			}

			this.$nextTick(() => {
				setTimeout(() => {
					this.initImages();
				},1000)
			})		
		},
		data(){
			return {												
				params: {	
					is_active: 1,
					images: [],							
				}
			}
		}
	}
</script>
<style scoped>
	input {
		border:1px solid #ced4da;
	}
	.member-place-list td.waiting{
	    color: #ffcc00 !important;
	}

	.member-place-list td.processing {
	    color: orange !important;
	}

	.member-place-list td.success {
	    color: #2dcb48 !important;
	}

	.member-place-list td.trash {
	    color: #e74807 !important;
	}
</style>