<template>
	<main id="main" :class="'site-main '+defaultLayoutList">
	   <div class="archive-city">
	      <div class="col-left">
	         <!-- <div class="archive-filter">
	            <h2>Publicidade</h2>
	            <template v-if="loadingBanners">
	            	<PuSkeleton v-for="n in 10" :key="n" style="height: 80px" />
	            </template>
	            <template v-else>
	            	<ads-banner-side :banners="bannerRows[0]"></ads-banner-side>
	            </template>
	         </div> -->
	         <!-- .archive-fillter -->
	         <div class="main-primary">	            
	         	<div align="center">
	            	<ads-banner-top></ads-banner-top>
	            </div>
	            <div class="top-area top-area-filter">
	            	<PuSkeleton style="height: 20px; width: 100%" v-if="loading"></PuSkeleton>
	                <div v-else class="filter-left">
	                  <span class="result-count">
	                  	Mostrando <span class="count">{{ partners.to }}</span> de <span class="count">{{ partners.total }}</span> registros
	                  </span>
	                </div>
	               <!-- <div class="filter-right">
	                  <div class="place-layout">
	                     <a class="active" href="#" @click="(defaultLayoutList = 'layout-grid')"><i class="las la-border-all icon-large"></i></a>
	                     <a class="" href="#" @click="(defaultLayoutList = 'layout-list')"><i class="las la-list icon-large"></i></a>
	                  </div>
	               </div> -->	              
	            </div>
	            <!--  -->
	            <list-partners></list-partners>
	            <!--  -->
	         </div>
	         <!-- .main-primary -->
	      </div>
	      <!-- .col-left -->
	      <div class="col-right" style="margin-top: 40px; flex: 0 0 19%">	      	
	         <div class="archive-filter" style="max-width:330px">
	            <h2>Publicidade</h2>

	            <template v-if="loadingBanners">
	            	<PuSkeleton v-for="n in 10" :key="n" style="height: 80px" />
	            </template>
	            <template v-else>
	            	<ads-banner-side :banners="this.banners"></ads-banner-side>
	            </template>
	         </div>
	      </div>
	      <!-- .col-right -->
	   </div>
	   <!-- .archive-city -->
	</main>
</template>
<script>
	import AdsBannerTop from '@/components/Ads/BannerTop';
	import AdsBannerSide from '@/components/Ads/BannerSide';
	import ListPartners from './ListPartners';
	import {mapGetters, mapActions} from 'vuex';
	import { chunk } from 'lodash';
	import $ from 'jquery';

	export default{
		components:{
			ListPartners,
			AdsBannerSide,
			AdsBannerTop
		},
		computed:{
			...mapGetters('Partner', ['loading', 'partners', 'city']),
			...mapGetters('Banner', {
				banners: 'banners',
				loadingBanners: 'loading'
			}),
			bannerRows(){				
				if(this.banners.length > 0){
					let total = parseInt(this.banners.length) / 1;					
					return chunk(this.banners, total);
				}

				return [];
			}
		},
		created() {
		  window.addEventListener("resize", this.resizeEvent);
		},
		destroyed() {
		  window.removeEventListener("resize", this.resizeEvent);
		},
		methods:{
			...mapActions('Banner', ['getBanners']),
			resizeEvent(e){
				if($(window).width() < 500){
					this.defaultLayoutList = 'layout-grid';
				}else{
					this.defaultLayoutList = 'layout-list';
				}
			}
		},
		mounted(){
			this.resizeEvent();
			this.getBanners({type_banner: 'page-search-side', city: this.city.slug});
		},
		data(){
			return{
				defaultLayoutList: 'layout-list',
			}
		}
	}
</script>