<template>
	<div v-if="Object.keys(partner).length > 0">
		<div ref="btnOpenmodal" class="d-lg-none d-block widget-reservation-mini open" align="center">
            <h3>Quer receber o contato da {{ partner.name }} ?</h3>
            <a @click="openModal" href="javascript:;" class="open-wg btn">Sim eu quero.</a>
        </div>
		<aside ref="widget" class="widget widget-shadow widget-message">
			<div v-if="successLead">
				<div class="alert alert-success">
					<h2>Sucesso :)</h2>
					<p>
						<i class="la la-check-circle"></i>
						A sua solicitação foi enviada, estaremos notificando a empresa <b>{{ partner.name }}</b> para realizar o retorno o quanto antes.<br>						
					</p>
				</div>
			</div>
			<div v-else>
				<h3>Solicitar contato {{ partner.name }}</h3>
				<form ref="formLead" @submit.prevent="handleSubmit" class="form-underline">
					<div class="field-input">
						<input type="text" placeholder="Seu nome*" value="" name="name" required>
					</div>
					<div class="field-input">
						<input type="email" placeholder="Seu e-mail" value="" name="email">
					</div>
					<div class="field-input">
						<the-mask name="mobile" :mask="['(##) #-####-####']" placeholder="Seu celular*" />
					</div>
					<div class="field-input">
						<input type="text" placeholder="Produto de interesse?" value="" name="product">
					</div>
					<div class="field-input">
						<textarea name="message" placeholder="Mensagem para empresa*" required></textarea>
					</div>
					<div class="field-input" style="margin-bottom: 10px;">
						Ao solicitar o contato você concorda com a nossa
						<a :href="$root.getUrlPath('politics')" target="_blank" rel="oppener"> política de privacidade</a>
					</div>
					<input type="submit" value="Solicitar contato">
				</form>
			</div>
		</aside><!-- .widget-message -->
	</div><!-- .sidebar -->
</template>
<script>	
	import PartnerModel from '@/api/Partner';
	import $ from 'jquery';
	import {mapGetters, mapActions} from 'vuex';
	import {TheMask} from 'vue-the-mask'

	export default{
		components:{
			TheMask,
		},
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		},
		methods: {
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),
			openModal(){
				this.$refs.widget.classList.add('open');
				this.$refs.btnOpenmodal.classList.remove('open');
			},
			async handleSubmit(){
		        const self = this;

				if (this.$refs.formLead.checkValidity()) {
				  let params = await this.$root.getAllData(this.$refs.formLead);				  
				  params.partner_id = this.partner.id;

				  this.setLoading(true);
				  PartnerModel.addLead(params).then(response => {
				  	this.successLead = true;
				  	this.setLoading(false);
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formLead.reportValidity();
				}
		    },
		},
		data(){
			return{
				successLead: false,
			}
		}
	}
</script>