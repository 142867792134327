<template>
	<div v-if="banner">		
		<a :target="(banner.url != '' ? '_blank' : '')" :href="(banner.url != '' ? banner.url : 'javascript:;')">
			<img :src="banner.desktop_path" class="img-fluid" style="margin-bottom: 10px">
		</a>		
	</div>
</template>
<script>	
	import Banner from '@/api/Banner';
	import {mapGetters, mapActions} from 'vuex';
	
	export default{
		components:{		
		},
		computed:{
			...mapGetters('Partner', ['city']),
		},
		methods:{			
		},
		mounted(){
			const self = this;

			let payload = {
				type_banner: 'page-search-top',
				city: this.city.slug,
			};

			Banner.getBannersActive(payload).then(function(response){
				let data = response.data.data;
				
				if(data.length > 0){
					self.banner = data[0];					
				}
			});
		},
		data(){
			return{	
				banner: {}			
			}
		}
	}
</script>