<template>
	<div class="">
		<h2 v-if="Object.keys(user).length > 0">Atualizar os dados da minha empresa</h2>
		<h2 v-else>Cadastrar minha empresa</h2>
		<form ref="formRegister" id="formRegister" @submit.prevent="handleSubmit" class="upload-form">
			<div class="listing-box" id="genaral">
				<form-general></form-general>
			</div><!-- .listing-box -->				
			<div class="listing-box" id="location">
				<form-address></form-address>
			</div><!-- .listing-box -->
			<div class="listing-box" id="contact">
				<form-contact></form-contact>
			</div><!-- .listing-box -->				
			<div class="listing-box" id="open">
				<form-hours></form-hours>
			</div><!-- .listing-box -->
			<div class="listing-box" id="media">
				<form-images></form-images>
			</div><!-- .listing-box -->
			<div class="field-group field-submit">
				<input type="submit" name="submit" value="Submit" class="btn">
			</div>
		</form>
	</div>
</template>
<script>
	import $ from 'jquery';
	import Partner from '@/api/Partner';
	import FormGeneral from './FormGeneral';
	import FormAddress from './FormAddress';
	import FormContact from './FormContact';
	import FormHours from './FormHours';
	import FormImages from './FormImages';
	import {mapActions, mapGetters} from 'vuex';

	export default{
		components: {
			FormGeneral,
			FormAddress,
			FormContact,
			FormHours,
			FormImages
		},
		computed: {
			...mapGetters('Partner', ['user']),
		},
		methods:{
			...mapActions('Site', {
				setError: 'setError',
				setLoading: 'loading',
			}),
			async validFiles(){
				var fileInput = $('.upload-file');
				var maxSize = fileInput.data('max-size');

				if(fileInput.get(0).files.length){
	                var fileSize = fileInput.get(0).files[0].size; // in bytes

	                if(fileSize>maxSize){
	                    alert('Existe um arquivo maior que ' + maxSize + ' kb');
	                    return false;
	                }
	            }

	            return true;
			},
			async handleSubmit(){
				const self = this;
				
				let checkFile = await this.validFiles();

				if(!checkFile){
					return;
				}

				let params = $("#formRegister").serializeObject();
				let formData = new FormData();

				$.each(params, function(index, value){
					if(typeof value == 'object'){
						formData.append(index, JSON.stringify(value));
					}else{
						formData.append(index, value);
					}					
				});

				var fileInput = $('.upload-file');
				if(fileInput.get(0).files.length){					
					formData.append('image_cover', fileInput.get(0).files[0]);
				}
				
				if(fileInput.get(1).files.length){					
					formData.append('image_profile', fileInput.get(1).files[0]);
				}

				let method = Object.keys(this.user).length > 0 ? 'updateRegister' : 'register';

				if (this.$refs.formRegister.checkValidity()) {
				  this.setLoading(true);
				  Partner[method](formData).then(response => {				  	
				  	this.setLoading(false);

				  	if(Object.keys(self.user).length > 0){
				  		this.$fire({
						  title: "Sucesso",
						  text: 'Registro atualizado com sucesso',
						  type: "success",			  
						}).then(r => {						
						});
				  	}else{
				  		this.$router.push({name: 'partner-success'});	
				  	}
				  	
				  }).catch(error => {
				  	this.setError(error);
				  });
				} else {
				  this.$refs.formRegister.reportValidity();
				}
			}
		}
	}
</script>