<template>
<main id="main" class="site-main">
	<div class="page-title page-title--small align-left" style="background-image: url(@/assets/images/bg/bg-about.png);">
		<div class="container">
			<div class="page-title__content">
				<h1 class="page-title__name">Disk Certo Digital</h1>
				<p class="page-title__slogan">Conheça nossa história</p>
			</div>
		</div>	
	</div><!-- .page-title -->
	<div class="site-content">
		<div class="container">
			<div class="company-info flex-inline">
				<img src="@/assets/images/diskcertodigital.png" alt="Disk Certo Digital">
				<div class="ci-content">
					<span>Seja muito bem vindo a Disk Certo Digital</span>
					<h2>Historia</h2>
					<p>
						A Disk Certo Digital, já está no mercado a mais de 20 anos informando os telefones comerciais de Cáceres/MT, Mirassol D’Oeste/MT e toda região do vale do Jauru.
					</p>
					<p>
						Atualmente, a Disk Certo Digital se posiciona no mercado como referência na área de informações de telefones comerciais, através dos seus portais de atendimento:
					</p>
					<p>
						* Centrais de tele atendimento (65) 3223-9002 na cidade de Cáceres e (65) 3241-4009 em Mirassol D’Oeste
						<br>
						* Pesquisa on-line pelo site www.diskcertodigital.com.br
						<br>
						* Aplicativo Disk Certo Digital, que permite que o usuário/internauta pesquise empresas mesmo estando off-line e já realize suas ligações.
						<br>
						Sendo assim, oferecemos serviços inteligentes, integrando a área de telecomunicações/internet com a área de relacionamento com o cliente, a fim de elevar a relação custo/benefício.
						<br>
					</p>
				</div>
			</div>
			<div class="company-info flex-inline">				
				<div class="ci-content">					
					<h2>Missão</h2>
					<p>
						Atender, informar e surpreender as pessoas através dos nossos portais de atendimento, sendo um agente contribuinte para as pessoas se comunicarem. Oferecendo benefícios e resultados para os nossos associados e para sociedade.
					</p>
					<h2>Visão</h2>
					<p>
						Inovar para ser referência e reconhecida pelos nossos clientes e sociedade como a melhor fonte de tele atendimento e marketing na área que atuamos, com colaboradores felizes, qualificados e realizados, resultando um negócio de alta rentabilidade.
					</p>
					<h2>Valores</h2>
					<p>
						Auto desenvolvimento, Atendimentos extraordinários Pessoas com energia positiva; Pessoas pro ativas e autossuficientes, Familia.
					</p>
				</div>
			</div>
			<div v-if="teams && teams.length > 0" class="our-team">
				<div class="container">
					<h2 class="title align-center">Conheça nossa equipe</h2>
				</div>
				<div class="ot-content grid grid-4">
					<div v-for="(team, index) in teams" :key="index" class="grid-item ot-item hover__box">
						<div class="hover__box__thumb">
							<img :src="team.image_path" :alt="team.name">
						</div>
						<div class="ot-info">
							<h3>{{ team.name }}</h3>
							<span class="job">{{ team.role }}</span>
						</div>
					</div>
				</div><!-- .ot-content -->
			</div><!-- .our-team -->			
		</div>
	</div><!-- .site-content -->
</main>
</template>
<script>
	import Site from '@/api/Site';
	import {mapGetters, mapActions} from 'vuex';
	
	export default{
		components: {
		},
		computed:{			
		},
		methods: {			
		},
		mounted(){
			const self = this;
			Site.getTeams().then(response => {
				self.teams = response.data.data;
			});
		},
		data(){
			return{
				teams: [],
			}
		}
	}
</script>