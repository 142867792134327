<template>
	<main id="main" class="site-main">
		<div class="site-content owner-content">
			<restrict-menu></restrict-menu>

			<div class="row">
				<div class="col-sm-12" align="center">
					<form-global />
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	import RestrictMenu from '../Menu';
	import FormGlobal from '@/views/Partner/Register/Form';

	export default{
		components: {
			RestrictMenu,
			FormGlobal			
		},		
	}
</script>