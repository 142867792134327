<template>
	<main id="main" class="site-main">
		<div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-checkout.png);">
			<div class="container">
				<div class="page-title__content">
					<h1 class="page-title__name">Sua empresa em destaque</h1>
					<p class="page-title__slogan">Faça sua empresa ter maior visibilidade na internet</p>
				</div>
			</div>	
		</div><!-- .page-title -->
		<div class="site-content">
			<div class="pricing-area">
				<div class="container">
					<h2 class="title align-center">Conheça nossos planos</h2>
					<div class="pricing-inner">
						<template v-if="plans.length == 0">
							<div class="row">
								<PuSkeleton  v-for="n in 3" :key="n" class="col-lg-4" style="height: 150px" />
							</div>
						</template>
						<div v-else-if="plans && plans.length > 0" class="row">
							<div class="col-lg-3 col-md-6">
								<div class="pricing-item">
									<!-- <div class="best-deal">Best Deal</div> -->
									<img :src="planFree.image_path" alt="Plano Grátis">
									<h3>Plano</h3>
									<div class="price">
										Grátis
									</div>
									<a :href="$root.getUrlPath('partner-register')" class="btn" title="Get Started">Quero começar</a>
									<ul>
										<li v-for="(permission, permissionIndex) in permissions"
											:key="permissionIndex">
											<template 
												v-if="planFree.permissions.indexOf(permission.slug) != -1">
												{{permission.name}}
											</template>
											<template v-else>
												<del>{{permission.name}}</del>
											</template>
										</li>										
									</ul>
								</div>
							</div>
							<div v-for="(plan, planIndex) in plansWithoutFree" :key="planIndex" class="col-lg-3 col-md-6">
								<div class="pricing-item">
									<!-- <div class="best-deal">Best Deal</div> -->
									<img :src="plan.image_path" :alt="plan.name">
									<h3>Plano</h3>
									<div class="price">
										{{ firstName(plan) }}
									</div>
									<a :href="$root.getUrlPath('contact')+'?origin=Assinar '+plan.name" class="btn" title="Get Started">Quero começar</a>
									<ul>
										<li v-for="(permission, permissionIndex) in permissions"
											:key="permissionIndex">
											<template 
												v-if="plan.permissions.indexOf(permission.slug) != -1">
												{{permission.name}}
											</template>
											<template v-else>
												<del>{{permission.name}}</del>
											</template>
										</li>										
									</ul>
								</div>
							</div>
							
						</div>
					</div><!-- .pricing-inner -->					
				</div>
			</div><!-- .pricing-area -->
			<div class="frequently-asked">
				<div class="container">
					<div class="title">
						<h2>Perguntas mais frequentes</h2>
						<p>Respostas para as perguntas mais frequentes.</p>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="asked-item">
								<h3>Por que devo investir na Disk Certo Digital?</h3>
								<p>A DiskCertoDigital.com.br é o maior portal de buscas de serviços e empresas de Cáceres e Região. É a melhor maneira de achar empresas sem pesquisas demoradas e com a credibilidade de uma marca presente a mais de 16 em Cáceres e Região. Procura por algum estabelecimento? Junte-se aos mais de 30 mil pessoas que acessam e relatam suas experiências no DiskCertoDigital.com.br mensalmente. Disponível também em aplicativo para smartphones e tablets.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="asked-item">
								<h3>Como faço para registrar minha empresa?</h3>
								<p>É fácil. Basta preencher o formulário de registro <a :href="$root.getUrlPath('partner-register')">clicando aqui</a>, e preencher todos os dados solicitados. Após o preenchimento será passado para o processo de aprovação da nossa equipe e quando aprovado o seu anúncio estará em nosso resultado de busca.</p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="asked-item">
								<h3>Quero contratar os serviços da Disk Certo Digital, como faço?</h3>
								<p>A Disk Certo Digital oferece diversas soluções para você conquistar mais clientes pela internet, alguns deles são Links e Banners em nosso site, Gestão de Marketing Digital e entre outros serviços, entre em contato conosco <a :href="$root.getUrlPath('contact')">clicando aqui!</a></p>
							</div>
						</div>
						<div class="col-md-6">
							<div class="asked-item">
								<h3>Sou anunciante, como faço para gerenciar meus dados?</h3>
								<p>Toda a gestão do seu registro é feito pelo painel do anunciante, uma área exclusiva para gerir tudo o que está acontecendo. 
									<a :href="$root.getUrlPath('partner-login')">
										Clique aqui para acessar
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div><!-- .frequently-asked -->
		</div><!-- .site-content -->
	</main>
</template>
<script>
	import Site from '@/api/Site';
	import {mapGetters, mapActions} from 'vuex';
	
	export default{
		components: {
		},
		computed:{
			plansWithoutFree(){
				if(this.plans.length > 0){
					return this.plans.filter((el) => {
						if(el.id != 4){
							return el;
						}
					});					
				}

				return [];
			},
			planFree(){
				if(this.plans.length > 0){
					return this.plans.find((el) => {
						if(el.id == 4){
							return el;
						}
					});					
				}

				return {};
			},		
		},
		methods: {
			firstName(plan){
				if(plan && plan.name){
					let firstName = plan.name.split(" ");
					return firstName[1];
				}

				return '';
			}			
		},
		mounted(){
			const self = this;
			Site.getPlans().then(response => {
				self.plans = response.data.data.plans;
				self.permissions = response.data.data.permissions;
			});
		},
		data(){
			return{
				plans: [],
				permissions: [],
			}
		}
	}
</script>
<style scoped>
	.pricing-item .price {
		font-size: 40px;
	}

	del {
		color: #bebebe;
	}
</style>