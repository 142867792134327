<template>
	<main id="main" class="site-main">
		<div class="page-title page-title--small align-left" style="background-image: url(images/bg/bg-checkout.png);">
			<div class="container">
				<div class="page-title__content">
					<h1 class="page-title__name">Call Center</h1>
					<p class="page-title__slogan">Tenha um time de telemarketing a sua disposição!</p>
				</div>
			</div>	
		</div><!-- .page-title -->
		<div class="site-content">
			<div class="pricing-area">
				<div class="container">
					<h2 class="title align-center">Telemarketing Ativo</h2>
					<div class="row">
						<div class="col-sm-12">
							<p>
								Você já parou para pensar como o tele-atendimento pode  auxiliar na ampliação do seu negócio?
							</p>
							<br>
							<p>
								Através deste contato direto com o cliente, podemos realizar os serviços de campanhas promocionais, pós-venda e reativação de clientes. A execução deste serviço agregará valor para sua empresa reduzindo seus custos operacionais, tais como pagamento de funcionários, alugueis e entre outros encargos do dia a dia, proporcionando desta forma um aumento na sua margem de lucro.
							</p>
							<br>
							<p>
								Com a nossa central de Call Center você terá a oportuniade de chegar até clientes que dificilmente seriam abordados pela sua empresa. O telefone reduz distâncias entre as pessoas e, por isso, você poderá  contatar clientes de todo o Brasil sem precisar se descolar.
							</p>
							<br>
						</div>
					</div>
					<h2 class="title align-center">Conheça nossos planos</h2>
					<div class="pricing-inner">
						<div class="row">
							<div class="col-lg-4">
								<div class="pricing-item">
									<img src="@/assets/images/call-center-start.png" alt="Basic">
									<h3>Plano Básico</h3>
									<div class="price"><span class="currency">R$</span>400,00<span class="time">mensal</span></div>
									<a :href="$root.getUrlPath('contact')+'?origin=Call Center Básico'" class="btn" title="Começar">Saber mais</a>
									<ul>
										<li>300 minutos</li>										
									</ul>
								</div>
							</div>

							<div class="col-lg-4">
								<div class="pricing-item">
									<img src="@/assets/images/call-center-start.png" alt="Basic">
									<h3>Plano Intermediário</h3>
									<div class="price"><span class="currency">R$</span>610,00<span class="time">mensal</span></div>
									<a :href="$root.getUrlPath('contact')+'?origin=Call Center Básico'" class="btn" title="Começar">Saber mais</a>
									<ul>
										<li>480 minutos</li>										
									</ul>
								</div>
							</div>

							<div class="col-lg-4">
								<div class="pricing-item">
									<img src="@/assets/images/call-center-start.png" alt="Basic">
									<h3>Plano Premium</h3>
									<div class="price"><span class="currency">R$</span>910,00<span class="time">mensal</span></div>
									<a :href="$root.getUrlPath('contact')+'?origin=Call Center Básico'" class="btn" title="Começar">Saber mais</a>
									<ul>
										<li>720 minutos</li>										
									</ul>
								</div>
							</div>
						</div>
					</div><!-- .pricing-inner -->					
				</div>
			</div><!-- .pricing-area -->
			<div class="frequently-asked">
				<div class="container">
					<div class="title">						
						<p>Os pacotes de minutagem são pré-pagos e os minutos são contabilizados a partir da conexão bem sucedida com o cliente.</p>
					</div>					
				</div>
			</div><!-- .frequently-asked -->
		</div><!-- .site-content -->
	</main>
</template>
<script>	
	import {mapGetters, mapActions} from 'vuex';
	
	export default{
		components: {
		},
		computed:{			
		},
		methods: {		
		},
		mounted(){
		},
		data(){
			return{
				plans: [],
				permissions: [],
			}
		}
	}
</script>
<style scoped>
	.pricing-item .price {
		font-size: 40px;
	}

	del {
		color: #bebebe;
	}
</style>