<template>
	<div v-if="partner && partner.working_hours && partner.working_hours.length > 0" class="store-detail">
       <span v-if="checkIfOpened()" class="open">Aberto agora</span>
       <span v-else class="open open-close">Fechado agora</span>
       <div class="toggle-select">
          <div class="toggle-show">
          	<span>{{ weekTrans[week[( $moment().isoWeekday()+'')]] }}</span>
          	{{ workingHours( $moment().isoWeekday()) }} <i class="las la-angle-down"></i>
          </div>
          <div class="toggle-list">
             <ul>
                <li v-for="n in 7" :key="n" :class="{active: (n == $moment().isoWeekday())}">
                	<span>{{ weekTrans[week[(n+'')]] }}</span>{{ workingHours(n) }}
                </li>                
             </ul>
          </div>
       </div>
    </div>
</template>
<script>
	import $ from 'jquery';
	import {mapGetters} from 'vuex';

	export default{
		computed: {
			...mapGetters('Partner', ['loading', 'partner']),
		},
		methods: {			
			checkIfOpened(){
				let dayNow = this.$moment().isoWeekday()+''; //1monday 7 sunday
				let hours = this.partner.working_hours[0];
				let day = hours[this.week[dayNow]];
				
				if(day.length == 0){
					return false;
				}

				let dayHours = day[0];

				let now = this.$moment();					
				let hourOpen = this.$moment((now.format('YYYY-MM-DD')+' '+dayHours.open+':00'));
				let hourClose = this.$moment((now.format('YYYY-MM-DD')+' '+dayHours.close+':00'));
				
				if(this.$moment().isAfter(hourOpen) && this.$moment().isBefore(hourClose)){
					return true;
				}else{
					return false;
				}
			},
			workingHours(dayWeek){
				let hours = this.partner.working_hours[0];
				let nameWeek = this.week[(dayWeek+'')];
				let week = hours[nameWeek];
				
				if(week.length == 0){
					return '-- não informado --';
				}

				let dayHours = week[0];

				return `${dayHours.open} AM - ${dayHours.close} PM`;
			}
		},
		mounted(){	
			this.$nextTick(() => {
				setTimeout(() => {
					$( '.toggle-show' ).on( 'click', function(e) {
			            e.preventDefault();
			            $( this ).parent().find( '.toggle-list' ).slideToggle();
			        });
				},1000);
			});
		},
		data(){
			return{				
				week: {
					'1': 'monday',
					'2': 'thursday',
					'3': 'wednesday',
					'4': 'tuesday',
					'5': 'friday',
					'6': 'saturday',
					'7': 'sunday',
				},
				weekTrans: {
					'monday' : 'Segunda-feira',
					'thursday' : 'Terça-feira',
					'wednesday' : 'Quarta-feira',
					'tuesday' : 'Quinta-feira',
					'friday' : 'Sexta-feira',
					'saturday' : 'Sabado',
					'sunday' : 'Domingo',
				}
			}
		}
	}
</script>
<style scoped>
	.open-close{
		color: red !important;
	}
</style>