<template>
	<div>
		<template v-for="(banner) in banners">
			<a :key="banner.id" :target="(banner.url != '' ? '_blank' : '')"  
				:href="(banner.url != '' ? banner.url : 'javascript:;')">
				<img :src="banner.desktop_path" class="img-fluid" style="margin-bottom: 10px">
			</a>
		</template>
	</div>
</template>
<script>
	export default{
		props: {
			banners: [Object, Array],
		},		
	}
</script>